import React, {useState} from "react";

import {
    Container,
    Row,
    Col,
    UncontrolledCarousel,
    Button,
    Card,
    CardImg,
    Badge,
    CardBody,
    Modal
} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {Link} from 'react-router-dom';
import {AppRoute, AppRouteTitles} from "../const";
import {Helmet} from "react-helmet";
import {Route} from '../modules/i18n';
import Cookies from "universal-cookie";
import {HorizontalSponsors} from '../modules/layout';
import YouTube, {Options} from 'react-youtube';

const items = [
    {src: require("../assets/img/race/carousel/c1.jpg"), altText: "", caption: "", header: ""},
    {src: require("../assets/img/race/carousel/m1.jpg"), altText: "", caption: "", header: ""},
    {src: require("../assets/img/race/carousel/m2.jpg"), altText: "", caption: "", header: ""},
    {src: require("../assets/img/race/carousel/n1.jpg"), altText: "", caption: "", header: ""},
    {src: require("../assets/img/race/carousel/n2.jpg"), altText: "", caption: "", header: ""},
    {src: require("../assets/img/race/carousel/n3.jpg"), altText: "", caption: "", header: ""},
    {src: require("../assets/img/race/carousel/t1.jpg"), altText: "", caption: "", header: ""},
    {src: require("../assets/img/race/carousel/t2.jpg"), altText: "", caption: "", header: ""},
];

export const Home: React.FC = () => {
    const {formatMessage} = useIntl();
    const yt: Options = {
        width: '100%',
        playerVars: {
            autoplay: 0
        }
    };

    function shuffle(a: any) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    const cookies = new Cookies();
    let state = false;
    if (cookies.get("covid19") === undefined) {
        state = true;
        cookies.set('covid19', "1", {path: '/', expires: new Date(Date.now() + 86400)});
    }
    const [modal, setModal] = useState(state);

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, [modal, setModal]);

    return (
        <>
            <Helmet>
                <title>SantYaGo10K 2020 - {formatMessage({id: "home.summary2"})}</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped pb-250">
                        <div className="shape shape-style-1 shape-orange">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            <del><FormattedMessage
                                                id="home.heading"
                                                values={{
                                                    sup: (chunks: React.ReactNode) => (
                                                        <sup>{chunks}</sup>
                                                    ),
                                                }}
                                            /></del>
                                            <span>{formatMessage({id: "home.subheading"})}</span>
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "home.summary1"})}
                                        </p>
                                        <p className="lead text-white">
                                            {formatMessage({id: "home.summary2"})}
                                        </p>
                                        <div className="btn-wrapper">
                                            <Button
                                                className="btn-white btn-icon mb-3 mb-sm-0 ml-1 text-orange"
                                                color="default"
                                                to={Route(AppRoute.Join)}
                                                tag={Link}
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fa fa-ticket"/>
                                                </span>
                                                <span className="btn-inner--text">
                                                    {formatMessage({id: AppRouteTitles.get(AppRoute.Join)})}
                                                </span>
                                            </Button>
                                            <Button
                                                className="btn-icon mb-3 mb-sm-0 ml-1"
                                                color="default"
                                                to={Route(AppRoute.Track)}
                                                tag={Link}
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fa fa-map-o"/>
                                                </span>
                                                <span className="btn-inner--text">
                                                    {formatMessage({id: AppRouteTitles.get(AppRoute.Track)})}
                                                </span>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                                            <UncontrolledCarousel items={shuffle(items)}/>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section">
                        <Container>
                            <Row className="row-grid align-items-center">
                                <Col md="6">
                                    <Card className="bg-default shadow border-0">
                                        <CardImg
                                            alt="..."
                                            src={require("../assets/img/race/track/home_preview.png")}
                                            top
                                        />
                                        <blockquote className="card-blockquote">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="svg-bg"
                                                preserveAspectRatio="none"
                                                viewBox="0 0 583 95"
                                            >
                                                <polygon
                                                    className="fill-default"
                                                    points="0,52 583,95 0,95"
                                                />
                                                <polygon
                                                    className="fill-default"
                                                    opacity=".2"
                                                    points="0,42 583,95 683,0 0,95"
                                                />
                                            </svg>
                                            <h4 className="display-3 font-weight-bold text-white">
                                                {formatMessage({id: "home.track.heading"})}
                                            </h4>
                                            <p className="lead text-italic text-white">
                                                {formatMessage({id: "home.track.subheading"})}
                                            </p>
                                        </blockquote>
                                    </Card>
                                </Col>
                                <Col md="6">
                                    <div className="pl-md-5">
                                        <div
                                            className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                                            <i className="fa fa-map"/>
                                        </div>
                                        <h3>{formatMessage({id: "during.track.title"})}</h3>
                                        <p className="lead">
                                            {formatMessage({id: "home.track.description"})}
                                        </p>
                                        <Link
                                            className="font-weight-bold text-warning mt-5"
                                            to={Route(AppRoute.Track)}
                                        >
                                            {formatMessage({id: "home.track.more"})}
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="section bg-secondary">
                        <Container>
                            <Row className="row-grid align-items-center">
                                <Col className="order-md-2" md="6">
                                    <YouTube
                                        videoId="utLNH1Krf-Q"
                                        className="floating"
                                        opts={yt}
                                    />
                                </Col>
                                <Col className="order-md-1" md="6">
                                    <div className="pr-md-5">
                                        <h3>{formatMessage({id: "home.intro.title"})}</h3>
                                        <p>
                                            {formatMessage({id: "home.intro.subtitle"})}
                                        </p>
                                        <ul className="list-unstyled mt-5">
                                            <li className="py-2">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <Badge
                                                            className="badge-circle mr-3"
                                                            color="warning"
                                                        >
                                                            <i className="fa fa-trophy"/>
                                                        </Badge>
                                                    </div>
                                                    <div>
                                                        <h6 className="mb-0">
                                                            <FormattedMessage
                                                                id="home.intro.l1"
                                                                values={{
                                                                    sup: (chunks: React.ReactNode) => (
                                                                        <sup>{chunks}</sup>
                                                                    ),
                                                                }}
                                                            />
                                                        </h6>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <Badge
                                                            className="badge-circle mr-3"
                                                            color="warning"
                                                        >
                                                            <i className="fa fa-users"/>
                                                        </Badge>
                                                    </div>
                                                    <div>
                                                        <h6 className="mb-0">
                                                            {formatMessage({id: "home.intro.l2"})}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <Badge
                                                            className="badge-circle mr-3"
                                                            color="warning"
                                                        >
                                                            <i className="fa fa-star"/>
                                                        </Badge>
                                                    </div>
                                                    <div>
                                                        <h6 className="mb-0">
                                                            {formatMessage({id: "home.intro.l3"})}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="section pb-0 bg-gradient-warning">
                        <Container>
                            <Row className="row-grid">
                                <Col lg="6" className="order-lg-2">
                                    <Card className="shadow shadow-lg--hover mt-5">
                                        <CardBody>
                                            <div className="d-flex px-3">
                                                <div>
                                                    <div
                                                        className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                        <i className="fa fa-money"/>
                                                    </div>
                                                </div>
                                                <div className="pl-4">
                                                    <h5 className="title text-warning">
                                                        {formatMessage({id: "home.join.price"})}&nbsp;
                                                        <Badge color="danger" pill className="mr-1">
                                                            {formatMessage({id: "home.join.not"})}
                                                        </Badge>
                                                    </h5>
                                                    <ul>
                                                        <li>{formatMessage({id: "home.join.regular"})}:&nbsp;
                                                            <Badge color="success" pill className="mr-1">
                                                                8.00 €
                                                            </Badge>
                                                        </li>
                                                        <li>{formatMessage({id: "home.join.last"})}:&nbsp;
                                                            <Badge color="info" pill className="mr-1">
                                                                13.00 €
                                                            </Badge>
                                                        </li>
                                                    </ul>
                                                    <p><i>{formatMessage({id: "home.join.discounts"})}</i></p>
                                                    <Link
                                                        className="text-warning"
                                                        to={Route(AppRoute.Join)}
                                                    >
                                                        {formatMessage({id: "before.join.title"})}
                                                    </Link>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className="shadow shadow-lg--hover mt-5">
                                        <CardBody>
                                            <div className="d-flex px-3">
                                                <div>
                                                    <div
                                                        className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                        <i className="fa fa-shower"/>
                                                    </div>
                                                </div>
                                                <div className="pl-4">
                                                    <h5 className="title text-warning">
                                                        {formatMessage({id: "during.showers.title"})}
                                                    </h5>
                                                    <p>
                                                        {formatMessage({id: "home.showers.description"})}
                                                    </p>
                                                    <Link
                                                        className="text-warning"
                                                        to={Route(AppRoute.Showers)}
                                                    >
                                                        {formatMessage({id: "home.seemore"})}
                                                    </Link>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className="shadow shadow-lg--hover mt-5">
                                        <CardBody>
                                            <div className="d-flex px-3">
                                                <div>
                                                    <div
                                                        className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                        <i className="fa fa-archive"/>
                                                    </div>
                                                </div>
                                                <div className="pl-4">
                                                    <h5 className="title text-warning">
                                                        {formatMessage({id: "during.clothes.title"})}
                                                    </h5>
                                                    <p>
                                                        {formatMessage({id: "home.clothes.description"})}
                                                    </p>
                                                    <Link
                                                        className="text-warning"
                                                        to={Route(AppRoute.Clothes)}
                                                    >
                                                        {formatMessage({id: "home.seemore"})}
                                                    </Link>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="6" className="order-lg-1">
                                    <Card className="shadow shadow-lg--hover mt-5">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require("../assets/img/race/start.png")}
                                        />
                                    </Card>
                                    <Card className="shadow shadow-lg--hover mt-5">
                                        <CardBody>
                                            <div className="d-flex px-3">
                                                <div>
                                                    <div
                                                        className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                        <i className="fa fa-car"/>
                                                    </div>
                                                </div>
                                                <div className="pl-4">
                                                    <h5 className="title text-warning">
                                                        {formatMessage({id: "before.parking.title"})}
                                                    </h5>
                                                    <p>
                                                        {formatMessage({id: "home.parking.description"})}
                                                    </p>
                                                    <Link
                                                        className="text-warning"
                                                        to={Route(AppRoute.Parking)}
                                                    >
                                                        {formatMessage({id: "home.seemore"})}
                                                    </Link>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className="shadow shadow-lg--hover mt-5">
                                        <CardBody>
                                            <div className="d-flex px-3">
                                                <div>
                                                    <div
                                                        className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                        <i className="fa fa-id-card"/>
                                                    </div>
                                                </div>
                                                <div className="pl-4">
                                                    <h5 className="title text-warning">
                                                        {formatMessage({id: "before.dorsal.title"})}
                                                    </h5>
                                                    <p>
                                                        {formatMessage({id: "home.dorsal.description"})}
                                                    </p>
                                                    <Link
                                                        className="text-warning"
                                                        to={Route(AppRoute.Parking)}
                                                    >
                                                        {formatMessage({id: "home.seemore"})}
                                                    </Link>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew zindex-100">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </section>
                    <br/>
                    <br/>
                    <section className="section pt-0">
                        <Container>
                            <Card className="bg-gradient-default shadow-lg border-0">
                                <div className="p-5">
                                    <Row className="align-items-center">
                                        <Col lg="7">
                                            <h3 className="text-white">
                                                {formatMessage({id: "home.circuit.title"})}
                                            </h3>
                                            <p className="lead mt-3 text-white">
                                                <FormattedMessage
                                                    id="home.circuit.description"
                                                    values={{
                                                        a: (chunks: React.ReactNode) => (
                                                            <a
                                                                className="text-white"
                                                                href="http://ciudadespatrimonio.org/cuidadodelpatrimonio/index.php?id=2200"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <u>{chunks}</u>
                                                            </a>
                                                        ),
                                                    }}
                                                />
                                            </p>
                                        </Col>
                                        <Col className="ml-lg-auto" lg="5">
                                            <img
                                                alt="..."
                                                className="img-fluid shadow-lg"
                                                src={require("../assets/img/race/others/circuit.png")}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Container>
                    </section>
                    <HorizontalSponsors/>
                </div>
            </main>

            <Modal
                className="modal-dialog-centered modal-danger"
                contentClassName="bg-gradient-warning"
                isOpen={modal}
                toggle={() => setModal(!modal)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">
                        COVID-19 "Coronavirus"
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(!modal)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">
                        <i className="ni ni-notification-70 ni-5x"/>
                        <h4 className="heading mt-4"><strong>{formatMessage({id: 'home.covid19.title'})}</strong></h4>
                        <p>
                            {formatMessage({id: 'home.covid19.info'})}

                        </p>
                        <strong>{formatMessage({id: 'home.covid19.newdate'})}</strong>
                    </div>
                </div>
                <div className="modal-footer">
                    <Link
                        className="btn btn-white"
                        color="default"
                        type="button"
                        to={Route(AppRoute.Covid19)}
                    >
                        {formatMessage({id: 'home.covid19.seemore'})}
                    </Link>
                    <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(!modal)}
                    >
                        {formatMessage({id: 'home.covid19.close'})}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default Home;
