import React from "react";

import {
    Container,
    Row,
    Col,
    Badge,
} from "reactstrap";
import {useIntl} from "react-intl";
import {CONTACT} from "../../const";
import {Helmet} from "react-helmet";
import {VerticalSponsors} from '../../modules/layout';

export const Regulation: React.FC = () => {
    const {formatMessage} = useIntl();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "info.regulation.title"})} | SantYaGo10K 2020</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-sm section-hero section-shaped">
                        <div className="shape shape-style-1 shape-dark">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="shape-container d-flex align-items-center py-lg">
                            <div className="col px-0">
                                <Row className="align-items-center justify-content-center">
                                    <Col className="text-center" lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "info.regulation.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "info.regulation.subtitle"})}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section section-sm">
                        <Container>
                            <Row>
                                <Col lg="8">
                                    <Row>
                                        <Col>
                                            <Badge color="info" pill className="mr-1">
                                                {formatMessage({id: "info.regulation.galician"})}
                                            </Badge>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h1 id="art1dataehora">ART. 1: Data e hora</h1>

                                            <p>O <strong>Club Atletismo Fontes do Sar</strong> e o <strong>Departamento
                                                Municipal de Deportes del</strong> <strong>Concello de
                                                Santiago</strong> coorganizan, coa colaboración especial do <strong>Multiusos
                                                Fontes do Sar - Complexo Deportivo Santa Isabel</strong>, e o control técnico
                                                do comité galego de xuíces da <strong>Federación Galega de Atletismo</strong>:
                                            </p>

                                            <p>A 6ª carreira nocturna SANTYAGO10K sobre unha distancia homologada de 10km en
                                                ruta, que se levará a cabo o vindeiro sábado <strong>2 de Maio de
                                                    2019</strong> e que dará comezo ás <strong>22:00 horas</strong>.</p>

                                            <h1 id="art2circuto">ART. 2: Circuíto</h1>

                                            <p>O circuíto foi medido e homologado pola Real Federación Española de Atletismo
                                                (RFEA) e discorrerá polo casco urbano da cidade de Santiago de Compostela.</p>

                                            <p>O circuíto será pechado ó tráfico e será acotado con valados e outros elementos
                                                nos tramos que non exista unha delimitación natural. Será obrigatorio para os
                                                participantes correr sempre dentro da zona acotada, estando prohibido o paso por
                                                beirarrúas, setos centrais e zonas axardinadas, se non se indica o contrario. O
                                                incumprimento desta norma, ou recortar distancia sobre o trazado oficial, poderá
                                                supoñer a descalificación do infractor</p>

                                            <p>Os únicos vehículos que poderán seguir a proba, serán os oficialmente sinalados
                                                pola organización.</p>

                                            <h1 id="art3controltcnico">ART. 3: Control técnico</h1>

                                            <p>A carreira estará baixo o control técnico do Comité Galego de Xuíces da
                                                Federación Galega de Atletismo.</p>

                                            <h1 id="art4pechedecontrol">ART. 4: Peche de control</h1>

                                            <p>O peche da proba realizarase unha hora despois do tempo empregado polo primeiro
                                                atleta en cruzar a liña de meta (aproximadamente 1 hora e 30 minutos despois da
                                                saída)</p>

                                            <h1 id="art5inscricins">ART. 5: Inscricións</h1>

                                            <p>A inscrición realizarase a través da pasarela de pago insertada na páxina web
                                                oficial do evento: <a
                                                    href="https://www.santyago10k.es/">https://www.santyago10k.es</a>, operativa
                                                ata as 23:59 horas do martes 30 de abril.</p>

                                            <p>O pago realizarase polas seguintes maneiras ou formas:</p>

                                            <ul>
                                                <li>De forma on-line, por medio de tarxeta de débito/crédito, a continuación do
                                                    propio proceso de rexistro.
                                                </li>

                                                <li>Habilitarase unha opción de pago presencial,presentando o xustificante de
                                                    inscrición na recepción do Multiusos Fontes do Sar (rúa Diego Bernal s/n,
                                                    Santiago de Compostela) ou no Complexo Deportivo Santa Isabel (rúa Tras
                                                    Santa Isabel s/n, Santiago de Compostela).
                                                </li>
                                            </ul>

                                            <p>Os prezos de inscrición, en función da data, serán os seguintes:</p>

                                            <ul>
                                                <li>En liña

                                                    <ul>
                                                        <li>Ata o <strong>domingo 21 de abril</strong> ás 23:59 horas: <strong>8
                                                            euros</strong></li>

                                                        <li>Dende o <strong>luns 22 de abril ata o martes 30 de
                                                            abril</strong> ás 23:59 horas: <strong>10 euros</strong></li>
                                                    </ul>
                                                </li>

                                                <li>Presencial <strong>(o importe será o correspondente coa data de pago, non de
                                                    preinscrición na web):</strong>


                                                    <ul>
                                                        <li>Ata o <strong>domingo 21 de abril</strong> ás 14:00 horas: <strong>8
                                                            euros</strong></li>

                                                        <li>Dende o <strong>luns 22 de abril ata o martes 30 de
                                                            abril</strong> ás 22:00 horas: <strong>10 euros</strong></li>
                                                    </ul>
                                                </li>
                                            </ul>

                                            <p>Non se aceptarán inscricións de ningún tipo, fora dos modos e prazos aquí
                                                indicados.</p>

                                            <p>(*) O Multiusos Fontes do Sar e o Complexo Deportivo Santa Isabel,
                                                realizarán un desconto especial ós seus aboados, deixando o custe da inscrición
                                                en* <strong>6 euros</strong> <em>; para desfrutalo, deberán realizar a
                                                    inscrición de forma presencial antes do domingo 21 de abril.</em></p>

                                            <h1 id="art5blmitedeinscricins">ART. 5.B: Límite de inscricións:</h1>

                                            <p>Establécese un <strong>límite de 2.500 participantes</strong>. Unha vez
                                                alcanzado o límite, contabilizando só as inscricións con pago confirmado,
                                                pecharase a posibilidade de inscrición.</p>

                                            <p></p>

                                            <h1 id="art6devolucindacotadeinscricin">ART. 6: Devolución da cota de
                                                inscrición:</h1>

                                            <p>Aceptarase só a devolución parcial da inscrición no caso de enfermidade ou grave
                                                lesión, previa presentación ó persoal da organización, do certificado médico
                                                oficial. Comunicarase previamente no correo electrónico (<a
                                                    href={"mailto:" + CONTACT}>{CONTACT}</a>) e
                                                indicaráselle como proceder.</p>

                                            <p>O importe da devolución virá determinado pola data de presentación do certificado
                                                médico. O 75% da cota se se presenta ata o domingo 21 de abril, ou o 50% se se
                                                presenta despois desa data. En caso contrario, non se procederá o reembolso.
                                                Para outros supostos xustificados, a organización valorará, se procede, a
                                                devolución parcial da cota.</p>

                                            <h1 id="art7entregadedorsaisechips">ART. 7: Entrega de dorsais e chips</h1>

                                            <p>Proximamente, a través da web do evento, informarase dos lugares concretos nos
                                                que se realizará a entrega de dorsais e chips</p>

                                            <p>As datas previstas para a recollida serán:</p>

                                            <ul>
                                                <li><strong>Venres 3</strong> de maio de 10:00 a 22:00 horas</li>

                                                <li><strong>Sábado 4</strong> de maio, día da carreira, de 10:00 a 21:00 horas
                                                </li>
                                            </ul>

                                            <h1 id="art8usodedorsaisechips">ART. 8: Uso de dorsais e chips</h1>

                                            <p>Os dorsais son persoais e intransferibles, deberanse colocar durante o transcurso
                                                da proba, na parte frontal do corpo, de forma totalmente visible e sen
                                                dobreces</p>

                                            <p>O chip tamén é persoal e intransferible e deberá asirse os cordóns da zapatilla
                                                ou en soporte adecuado para o nocello.</p>

                                            <p>Non serán admitidos pola organización corredores que non están inscritos
                                                oficialmente ou corran sen dorsal ou sen chip, ou que pertenzan a outra
                                                carreira</p>

                                            <p>O incumprimento de calquera punto deste artigo será motivo de descalificación e
                                                verase obrigado a abandonar o trazo na defensa dos dereitos dos corredores
                                                lexitimamente inscritos.</p>

                                            <h1 id="art9devolucindechips">ART. 9: Devolución de chips</h1>

                                            <p>En caso de que se utilicen chips para o cronometraxe da proba, será obrigatorio a
                                                devolución dos chips unha vez rematada a carreira, nos puntos de recollida
                                                sinalizados e habilitados para tal efecto na zona de meta.</p>

                                            <p>En caso de esquecemento de forma involuntaria, rogase contactar ca organización
                                                no correo electrónico (<a
                                                    href={"mailto:" + CONTACT}>{CONTACT}</a>) e
                                                indicaráselle como proceder.</p>

                                            <p></p>

                                            <h1 id="art10cronometraxe">ART. 10: Cronometraxe</h1>

                                            <p> A cronometraxe estará a cargo da empresa EME SPORT, mediante o sistema sport tag
                                                ou chip.</p>

                                            <p>Habilitaranse varios puntos de control o longo do percorrido. Os participantes
                                                que non pasen por tódolos puntos de control, poderán ser descalificados, si así
                                                o decide o xuíz arbitro da proba.</p>

                                            <h1 id="art11caixnsdesada">ART. 11: Caixóns de saída</h1>

                                            <p>No momento da inscrición os participantes poderán acreditar unha marca oficial
                                                obtida anteriormente en carreiras de 10km en ruta homologadas. En base a ditas
                                                marcas asignaranse diferentes caixóns de saída que axudarán a que a mesma sexa
                                                máis fluída e ordeada. Dita distribución reflexarase nos dorsais proporcionados
                                                pola organización.</p>

                                            <p>A organización terá o dereito de reservar determinadas prazas para atletas
                                                invitados expresamente para o evento.</p>

                                            <p>Estableceranse polo menos catro caixóns de saída, tendo a organización a opción
                                                de modificar este reparto en función do número de atletas inscritos que
                                                acrediten cada marca, dividindo en máis grupos si se considera beneficioso para
                                                a seguridade da proba. Os caixóns previstos son:</p>

                                            <ul>
                                                <li>Caixón 1: Marcas inferiores a 40 minutos</li>

                                                <li>Caixón 2: Marcas entre 40 e 45 minutos</li>

                                                <li>Caixón 3: Marcas entre 45 e 55 minutos</li>

                                                <li>Caixón 4: Marcas máis de 55 minutos</li>

                                                <li>Caixón 5: Sen marca previa</li>
                                            </ul>

                                            <p>Os corredores estarán nos seus postos 10 minutos antes do disparo oficial da
                                                saída.</p>

                                            <p></p>

                                            <h1 id="art12categoras">ART. 12: Categorías</h1>

                                            <p>Establécense as seguintes categorías de competición:</p>

                                            <ul>
                                                <li>Xuvenil: atletas nados/as entre 2002 e 2003</li>

                                                <li>Junior: atletas nados/as entre 2000 e 2001;</li>

                                                <li>Promesa: atletas nados/as entre 1997 e 1999;</li>

                                                <li>Sénior: atletas nados/as entre 1985 e 1996;</li>

                                                <li>Veteráns/as A: atletas nados/as entre 1980 e 1984;</li>

                                                <li>Veteráns/as B: atletas nados/as entre 1970 e 1979;</li>

                                                <li>Veteráns/as C: atletas nados/as entre 1960 e 1969;</li>

                                                <li>Veteráns/as D: atletas nados/as en 1959 e anteriores.</li>
                                            </ul>

                                            <h1 id="art13mediosfsicos">ART. 13: Medios físicos</h1>

                                            <p>Os participantes deberán competir polos seus propios medios físicos naturais, sen
                                                axudas materias de ningún tipo. Calquera dúbida o respecto poderá ser consultada
                                                ca organización a través do correo electrónico (<a
                                                    href={"mailto:" +  CONTACT}>{CONTACT}</a>) e
                                                indicaráselle como proceder.</p>

                                            <p>A excepción dos designados pola organización, non está permitido o acompañamento
                                                na carreira por ningún vehículo, bicicleta, patíns ou persoas non inscritas na
                                                carreira (acompañantes, para avituallamento ou para marcar ritmos).</p>

                                            <p>Debido que as condicións do trazado non son óptimas e velando pola súa
                                                seguridade, quedan excluídas de participación, cadeiras de rodas, coches e
                                                cadeiras de nenos, animais, nin correr acompañados en ningún momento.</p>

                                            <h1 id="art14avituallamentos">ART. 14: Avituallamentos</h1>

                                            <p>Seguindo as normas da FGA e RFEA para probas en ruta, instalarase un posto de
                                                avituallamento líquido arredor do quilómetro 5 e outro na liña de meta.</p>

                                            <p>Poñeranse a disposición dos corredores, contedores para a recollida de lixo, de
                                                uso obrigatorio.</p>

                                            <h1 id="art15servizosmdicos">ART. 15: Servizos médicos</h1>

                                            <p>A proba dispoñerá dos servizos Médicos obrigatorios, tanto na liña de meta coma
                                                en varios puntos o longo do percorrido. Así mesmo a organización poñerá a
                                                disposición dos atletas un servizo de fisioterapia na zona da meta.</p>

                                            <p>Recomendamos a tódolos participantes encher a parte posterior do dorsal cos datos
                                                de nome e apelidos completos, teléfono en caso de emerxencia, se está baixo
                                                algún tratamento específico, se ten algún problema médico (alerxias, atencións
                                                especiais, etc), así coma o seu grupo sanguíneo.</p>

                                            <h1 id="art16outrosservizos">ART. 16: Outros servizos</h1>

                                            <p>A organización poñerá a disposición dos participantes un servizo de gardarroupa
                                                preto da liña de saída, avituallamento de meta e duchas a finalización da proba.
                                                Tamén habilítanse e sinalizaranse baños na zoa de saída, meta e outros puntos do
                                                percorrido.</p>

                                            <p>Darase máis información detallada sobre localización de aparcadoiro, duchas,
                                                gardarroupa, e outros servizos a través da web da carreira <a
                                                    href="https://www.santyago10.es/">https://www.santyago10.es</a></p>

                                            <p></p>

                                            <h1 id="art17clasificacin">ART. 17: Clasificación</h1>

                                            <p>Realizaranse as seguintes clasificacións:</p>

                                            <ul>
                                                <li>Clasificación xeral absoluta, masculina e feminina;</li>

                                                <li>Clasificación por categorías, masculina e feminina: Xuvenil, Junior,
                                                    Promesa, Sénior, Veteráns A, B, C, D.
                                                </li>
                                            </ul>

                                            <h1 id="art18premios">ART. 18: Premios</h1>

                                            <p>Repartiranse os seguintes premios en metálico:</p>

                                            <ul>
                                                <li>Categoría masculina:


                                                    <ul>
                                                        <li>1ª posición absoluta: 500 €</li>

                                                        <li>2ª posición absoluta: 300 €</li>

                                                        <li>3ª posición absoluta: 200 €</li>
                                                    </ul>
                                                </li>

                                                <li>Categoría feminina:


                                                    <ul>
                                                        <li>1ª posición absoluta: 500 €</li>

                                                        <li>2ª posición absoluta: 300 €</li>

                                                        <li>3ª posición absoluta: 200 €</li>
                                                    </ul>
                                                </li>

                                                <li>Premio especial Empadroados en Santiago de Compostela, masculino e feminino:


                                                    <ul>
                                                        <li>1º 100€</li>

                                                        <li>2º 75€</li>

                                                        <li>3º 50€</li>
                                                    </ul>
                                                </li>
                                            </ul>

                                            <p>Tamén obterán trofeo ós tres primeiros clasificados masculino e feminino de cada
                                                categoría.</p>

                                            <p></p>

                                            <p>Os dorsais reservados para os peregrinos e para a organización, non optarán a
                                                ningún premio xa que estes dorsais serán inscritos no mesmo día da proba.
                                                Entrarán no cronometraxe da proba pero non no cómputo para os premios.</p>

                                            <p>Ó marxe premiaranse as seguintes categorías especiais:</p>

                                            <ul>
                                                <li>Club Federado con máis participantes chegados a meta: 100€.</li>

                                                <li>Grupo Non Federado con máis participantes chegados a meta: 100€.</li>

                                                <li>Grupo Non Federado con máis participantes femininas chegadas a meta: 100€.
                                                </li>

                                                <li>Premio especial “ <strong>Cidades Patrimonio da Humanidade</strong> ”. Club
                                                    ou grupo que proceda dende máis lonxe (fora de Galicia), mínimo tres
                                                    participantes: 100€
                                                </li>

                                                <li>Premio especial <strong><em>“Cándido Calvo”</em></strong> <strong><em>,</em></strong> para
                                                    o corredor e corredora de maior 100€.
                                                </li>
                                            </ul>

                                            <p>Para poder optar ós premios é importante coñecer que:</p>

                                            <ul>
                                                <li>Os premios non serán acumulables.</li>

                                                <li>Para optar a participación por Club Federado ou Grupo NON Federado, os
                                                    atletas deberán realizar a súa inscrición individualmente e posteriormente
                                                    deberán enviar por correo electrónico a dirección <a
                                                        href={"mailto:" +  CONTACT}>{CONTACT}</a> un listado
                                                    detallando os membros computables o efecto.
                                                </li>

                                                <li>En caso de empate entre clubs/grupos, premiarase o que menor suma total de
                                                    tempos empregasen en rematar a proba, igualmente para os corredores de maior
                                                    idade.
                                                </li>

                                                <li>Os premiados na categoría de corredores de maior idade deberán acreditar a
                                                    súa idade cun documento oficial (DNI, Pasaporte ou similar).
                                                </li>
                                            </ul>

                                            <h1 id="art19entregadepremios">ART. 19: Entrega de premios</h1>

                                            <p>O día da carreira, trala publicación de resultados no taboleiro de anuncios,
                                                farase a entrega de premios no estrado instalado na Praza do Obradoiro.</p>

                                            <p>Os atletas premiados deberán atoparse na zona do pódium e estar atentos a súa
                                                chamada no momento que se faga o anuncio por altofalantes. Será necesario
                                                presentar o DNI ou pasaporte a efectos de identificación.</p>

                                            <h1 id="art20reclamacins">ART. 20: Reclamacións</h1>

                                            <p>Tódalas reclamación deberán efectuarse verbalmente o Xuíz Árbitro da proba,
                                                situado na zona de meta, non máis tarde de 30 minutos despois da hora da
                                                publicación dos resultados oficiais.</p>

                                            <h1 id="art21descalificacins">ART. 21: Descalificacións</h1>

                                            <p>Os membros da organización resérvanse a facultade de retirar da proba, o atleta
                                                que manifeste un mal estado físico, non facilite á organización a documentación
                                                que se lle requira ou os corredores/as que non respeten as indicacións do
                                                persoal da Federación Galega de Atletismo, Policía Local, Protección Civil,
                                                Servizos Médicos, Voluntarios ou calquera membro da organización debidamente
                                                identificado.</p>

                                            <p>Os Xuíces da proba terán a facultade de descalificar por infracción a quen non
                                                leve visiblemente o seu dorsal, o manipule e/ou ceda a outro, altere os datos
                                                facilitados a organización con respecto o que figuren no seu DNI ou ficha
                                                federativa, non complete o percorrido establecido ou incumpra calquera outra
                                                norma contemplada nas Normas da FGA, RFEA ou IAAF.</p>

                                            <p>Os corredores/as descalificados/as non terán opción a premios nin trofeos.</p>

                                            <h1 id="art22responsabilidade">ART. 22: Responsabilidade</h1>

                                            <p>O participante declara, baixo a súa responsabilidade, que realizou o
                                                recoñecemento médico que lle permite estar en condicións óptimas para realizar a
                                                proba, eximindo á organización de dita responsabilidade.</p>

                                            <p>A organización dispón dun Seguro de Accidentes e Responsabilidade Civil, que
                                                cubrirá as incidencias da proba.</p>

                                            <h1 id="art23participacindeestranxeiros">ART. 23: Participación de estranxeiros</h1>

                                            <p>A participación de atletas estranxeiros en competicións de ruta autonómicas
                                                estará regulada pola normativa publicada na páxina oficial da RFEA, no seu
                                                artigo 3.4 (páxina 72 do manual), artigo que poderán consultar no seguinte
                                                enlace: <a
                                                    href="https://www.rfea.es/normas/pdf/reglamento2015/00Reglamentacion2015.pdf">Reglamentación
                                                    RFEA 2014-2015</a></p>

                                            <p></p>

                                            <h1 id="art24proteccindedatos">ART. 24: Protección de datos</h1>

                                            <p>Tódolos corredores/as o realizar a inscrición, aceptan a publicación do seu nome
                                                e imaxe nas clasificacións, medios de comunicación, internet, ou calquera outro
                                                medio que a organización puidera requirir.</p>

                                            <p>Mediante a inscrición, o participante consente expresamente que os seus datos
                                                sexan cedidos e utilizados polos organizadores de eventos, as empresas que
                                                participan na organización, os patrocinadores e a Federación Galega de
                                                Atletismo, En todo caso, e de conformidade có establecido no RGPD, informase que
                                                os datos de carácter persoal declarados pasarán a formar parte de:</p>

                                            <ul>
                                                <li>Un ficheiro, cuxo responsable de tratamento será o Clube de Atletismo Fontes
                                                    do Sar, e cuxa finalidade será a xestión de actividades realizadas baixo a
                                                    responsabilidade do Concello. Os dereitos de acceso, rectificación,
                                                    cancelación e oposición dos datos persoais neste ficheiro poderán facerse
                                                    por correo electrónico na dirección <a
                                                        href={"mailto:" +  CONTACT}>{CONTACT}</a></li>

                                                <li>Un ficheiro que como responsable de tratamento será a Federación Galega de
                                                    Atletismo, e cuxa finalidade será a xestión de actividades realizadas baixo
                                                    a responsabilidade da Federación Galega de Atletismo. Os dereitos de acceso,
                                                    rectificación, cancelación e oposición dos datos persoais neste ficheiro
                                                    poderán facerse ante a Federación Galega de Atletismo (Praza Agustín Díaz nº
                                                    1 - Complexo Deportivo de Elviña - 15002 A Coruña).
                                                </li>

                                                <li>Esta proba pertence ó Calendario Oficial da Federación Galega de Atletismo e
                                                    mediante a súa inscrición, o corredor/a consinte expresamente que os seus
                                                    datos básicos (Nome, Apelidos, NIF/NIE/Pasaporte, Data de Nacemento, Sexo e
                                                    Código Postal de residencia), sexan enviados a Real Federación Española de
                                                    Atletismo, para a xestión das licenzas de día, resultados e ranking. A
                                                    posesión dunha licenza garante que o corredor/a participa cuberto polo
                                                    seguro de responsabilidade civil e accidentes deportivos.
                                                </li>
                                            </ul>

                                            <h1 id="art25compromiso">ART. 25: Compromiso</h1>

                                            <p>A inscrición na carreira implica a plena aceptación do presente regulamento.</p>

                                            <h1 id="art26cambiosnoregulamento">ART. 26: Cambios no regulamento</h1>

                                            <p>A organización reservase o dereito de facer algún cambio no presente regulamento,
                                                se así fose necesario, debendo comunícalo na web oficial, redes sociais e por
                                                medio de correo electrónico a aqueles inscritos que o facilitaran durante a
                                                inscrición.</p>

                                            <h1 id="consideracinfinal">CONSIDERACIÓN FINAL</h1>

                                            <p>Todo o non reflectido no presente regulamento rexerase polas normas da FGA, RFEA
                                                e da IAAF.</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="4">
                                    <VerticalSponsors/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Regulation;
