interface Sponsors {
    id: string,
    name: string,
    link: string
}

export const EnterpriseSponsors: Sponsors[] = [
    {
        id: "eci",
        name: "El Corte Inglés",
        link: "https://www.elcorteingles.es/"
    },
    {
        id: "pm",
        name: "Pentax Medical",
        link: "https://www.pentaxmedical.com/"
    }
];

export const BusinessSponsors: Sponsors[] = [
    {
        id: "cenor",
        name: "Cenor Electrodomésticos",
        link: "https://www.cenor.es/"
    },
    {
        id: "bc",
        name: "Bétula Cars",
        link: "https://www.betulacars.es/"
    },
    {
        id: "anova",
        name: "Anova",
        link: "https://www.millasur.com/"
    },
    {
        id: "tt",
        name: "TeeTravel",
        link: "https://www.tee-travel.com/"
    }
];

export const BasicSponsors: Sponsors[] = [
    {
        id: "cd",
        name: "Centro Deportivo",
        link: "http://centrodeportivo.es/"
    },
    {
        id: "og",
        name: "Oral Group",
        link: "https://oralgroup.es/"
    }
];

export const StarterSponsors: Sponsors[] = [
    {
        id: "hoko",
        name: "Hoko",
        link: "https://www.hokosport.com/"
    },
    {
        id: "amobla",
        name: "Amobla",
        link: "http://amobla.es/"
    },
    {
        id: "lc",
        name: "LauCreaciones",
        link: "http://laucreaciones.com/"
    }
];


export const Organizers: Sponsors[] = [
    {
        id: "concello",
        name: "Deportes - Concello de Santiago",
        link: "http://santiagodecompostela.gal/"
    },
    {
        id: "fga",
        name: "Federación Galega de Atletismo",
        link: "https://atletismo.gal/"
    },
    {
        id: "rfea",
        name: "Real Federación Española de Atletismo",
        link: "https://www.rfea.es/"
    },
    {
        id: "cafs",
        name: "Clube Atletismo Fontes do Sar",
        link: "https://www.atletismofontesdosar.es/"
    },
    {
        id: "mfs",
        name: "Multiusos Fontes do Sar",
        link: "https://www.multiusos.net/"
    }
];

export const Partners: Sponsors[] = [
    {
        id: "pl",
        name: "Policía Local - Santiago de Compostela",
        link: "http://santiagodecompostela.gal/casa_concello/servizo.php?txt=ser_policia_municipal&lg=cas&c=36"
    },
    {
        id: "pc",
        name: "Protección Civil - Santiago de Compostela",
        link: "http://santiagodecompostela.gal/casa_concello/servizo.php?txt=ser_proteccion_civil&lg=cas&c=36"
    },
    {
        id: "cv",
        name: "Cruz Vermella",
        link: "https://www.cruzvermella.org/"
    },
    {
        id: "xg",
        name: "Xunta de Galicia",
        link: "https://www.xunta.gal/"
    },
    {
        id: "dc",
        name: "Deputación da Coruña",
        link: "https://www.dacoruna.gal/portada/"
    },
    {
        id: "dg",
        name: "Deporte Galego",
        link: "https://deporte.xunta.gal/"
    },
    {
        id: "ts",
        name: "Turismo de Santiago",
        link: "https://www.santiagoturismo.com/"
    },
    {
        id: "ffc",
        name: "Foto-Forum Compostela",
        link: "http://www.fotoforumcompostela.com/"
    },
    {
        id: "k",
        name: "Kodular",
        link: "https://www.kodular.io"
    }
];
