import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { AppRoute, AppLanguage } from './const';
import * as views from './views';
import { AppLayout } from './modules/layout';
import { LocalizedRouter, LocalizedSwitch, appStrings } from './modules/i18n';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-157998483-1');

export const App: React.FC = () => (
    <LocalizedRouter
        RouterComponent={BrowserRouter}
        languages={AppLanguage}
        appStrings={appStrings}
    >
        <AppLayout>
            <LocalizedSwitch>
                <Route exact path={AppRoute.Home}><views.Home /></Route>
                <Route exact path={AppRoute.Covid19}><views.Covid19 /></Route>
                {/** BEFORE */}
                <Route exact path={AppRoute.Join}><views.Join /></Route>
                <Route exact path={AppRoute.GetTo}><views.GetTo /></Route>
                <Route exact path={AppRoute.Parking}><views.Parking /></Route>
                <Route exact path={AppRoute.Eating}><views.Eating /></Route>
                <Route exact path={AppRoute.Chip}><views.Chip /></Route>
                <Route exact path={AppRoute.Bag}><views.Bag /></Route>
                {/** DURING */}
                <Route exact path={AppRoute.Track}><views.Track /></Route>
                <Route exact path={AppRoute.Clothes}><views.Clothes /></Route>
                <Route exact path={AppRoute.Drinking}><views.Drinking /></Route>
                <Route exact path={AppRoute.Showers}><views.Showers /></Route>
                <Route exact path={AppRoute.Prizes}><views.Prizes /></Route>
                {/** AFTER */}
                <Route exact path={AppRoute.Dinner}><views.Dinner /></Route>
                <Route exact path={AppRoute.Sleeping}><views.Sleeping /></Route>
                <Route exact path={AppRoute.Visiting}><views.Visiting /></Route>
                {/** INFO */}
                <Route exact path={AppRoute.PastYears}><views.PastYears /></Route>
                <Route exact path={AppRoute.Regulation}><views.Regulation /></Route>
                <Route exact path={AppRoute.Terms}><views.Terms /></Route>
                <Route exact path={AppRoute.Press}><views.Press /></Route>
                {/** OTHERS */}
                <Route exact path={AppRoute.Contact}><views.Contact /></Route>
                <Route exact path={AppRoute.Volunteer}><views.Volunteer /></Route>
                {/** ADMIN */}
                <Route exact path={AppRoute.Admin}><views.Admin /></Route>
                <Route exact path={AppRoute.Calendar}><views.Calendar /></Route>
                {/** 404 */}
                <Route path="*"><views.NotFound /></Route>
            </LocalizedSwitch>
        </AppLayout>
    </LocalizedRouter>
);

export default App;