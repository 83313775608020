import React from "react";
import Helmet from "react-helmet";

export const OneSignal: React.FC = () => {
    return (
        <Helmet script={
            [
                {type: 'text/javascript', src: "https://cdn.onesignal.com/sdks/OneSignalSDK.js"},
                {type: 'text/javascript', innerHTML: `
                    var OneSignal = window.OneSignal || [];
                    OneSignal.push(function () {
                        OneSignal.init({
                            appId: "cd1e0deb-89bf-484c-9f4c-e81fcafd3edb",
                            allowLocalhostAsSecureOrigin: true,
                        });
                    });
                `}
            ]}
        >
        </Helmet>
    );
};

export default OneSignal;