import React from "react";
import {Helmet} from 'react-helmet';

import {
    Container,
    Row,
    Col
} from "reactstrap";
import Protect from "../../modules/password/Controller";


export const Calendar: React.FC = () => {
    React.useEffect(() => {
        Protect();
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Helmet>
                <title>Calendario | SantYaGo10K 2020</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped pb-250">
                        <div className="shape shape-style-1 bg-gradient-gray">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            Calendario
                                        </h1>
                                        <p className="lead text-white">
                                            Dispoñible en Google Calendar
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section pt-lg-0 mt--200">
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg="12">
                                    <iframe
                                        title="calendar"
                                        src="https://calendar.google.com/calendar/embed?src=ksj429pn2rk5vs4bt7tsve4d9o%40group.calendar.google.com&color=%23F4511E&wkst=2&ctz=Europe%2FMadrid"
                                        style={{border: 0}} width="100%" height="600" frameBorder="0"
                                        scrolling="no">
                                    </iframe>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Calendar;
