import React from "react";
import {Helmet} from 'react-helmet';

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Badge,
} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {VerticalSponsors} from "../../modules/layout";
import {CONTACT} from "../../const";


export const Prizes: React.FC = () => {
    const {formatMessage} = useIntl();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "during.prizes.title"})} | SantYaGo10K 2020</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped">
                        <div className="shape shape-style-1 bg-gradient-orange">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "during.prizes.title"})}{" "}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "during.prizes.subtitle"})}
                                        </p>
                                    </Col>
                                    <Col lg="6">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require('../../assets/img/race/pages/prizes.jpg')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section section-sm">
                        <Container>
                            <Row>
                                <Col lg="8">
                                    <Badge color="warning" pill className="mr-1">
                                        {formatMessage({id: "wip.confirmation"})}
                                    </Badge>
                                    <h1>{formatMessage({id: "during.prizes.heading"})}</h1>
                                    <Row>
                                        <Col lg="6">
                                            <p>{formatMessage({id: "during.prizes.subheading"})}</p>
                                        </Col>
                                        <Col lg="6">
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require('../../assets/img/undraw/prizes.png')}
                                            />
                                        </Col>
                                        <Col lg="12">
                                            <p>{formatMessage({id: "during.prizes.p"})}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <Card className="shadow shadow-lg--hover mt-3">
                                                <CardBody>
                                                    <div className="d-flex px-1">
                                                        <div className="pl-2">
                                                            <h5 className="title text-warning">
                                                                {formatMessage({id: "during.prizes.masc"})}
                                                            </h5>
                                                            <p>
                                                                <FormattedMessage
                                                                    id="during.prizes.pos1"
                                                                    values={{
                                                                        sup: (chunks: React.ReactNode) => (
                                                                            <sup>{chunks}</sup>
                                                                        ),
                                                                    }}
                                                                />: 500€
                                                            </p>
                                                            <p>
                                                                <FormattedMessage
                                                                    id="during.prizes.pos2"
                                                                    values={{
                                                                        sup: (chunks: React.ReactNode) => (
                                                                            <sup>{chunks}</sup>
                                                                        ),
                                                                    }}
                                                                />: 300€
                                                            </p>
                                                            <p>
                                                                <FormattedMessage
                                                                    id="during.prizes.pos3"
                                                                    values={{
                                                                        sup: (chunks: React.ReactNode) => (
                                                                            <sup>{chunks}</sup>
                                                                        ),
                                                                    }}
                                                                />: 200€
                                                            </p>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="6">
                                            <Card className="shadow shadow-lg--hover mt-3">
                                                <CardBody>
                                                    <div className="d-flex px-1">
                                                        <div className="pl-2">
                                                            <h5 className="title text-warning">
                                                                {formatMessage({id: "during.prizes.fem"})}
                                                            </h5>
                                                            <p>
                                                                <FormattedMessage
                                                                    id="during.prizes.pos1"
                                                                    values={{
                                                                        sup: (chunks: React.ReactNode) => (
                                                                            <sup>{chunks}</sup>
                                                                        ),
                                                                    }}
                                                                />: 500€
                                                            </p>
                                                            <p>
                                                                <FormattedMessage
                                                                    id="during.prizes.pos2"
                                                                    values={{
                                                                        sup: (chunks: React.ReactNode) => (
                                                                            <sup>{chunks}</sup>
                                                                        ),
                                                                    }}
                                                                />: 300€
                                                            </p>
                                                            <p>
                                                                <FormattedMessage
                                                                    id="during.prizes.pos3"
                                                                    values={{
                                                                        sup: (chunks: React.ReactNode) => (
                                                                            <sup>{chunks}</sup>
                                                                        ),
                                                                    }}
                                                                />: 200€
                                                            </p>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="6">
                                            <Card className="shadow shadow-lg--hover mt-2">
                                                <CardBody>
                                                    <div className="d-flex px-1">
                                                        <div className="pl-2">
                                                            <h5 className="title text-warning">
                                                                "Santiagueses"
                                                            </h5>
                                                            <p>
                                                                <FormattedMessage
                                                                    id="during.prizes.stgo.pos1"
                                                                    values={{
                                                                        sup: (chunks: React.ReactNode) => (
                                                                            <sup>{chunks}</sup>
                                                                        ),
                                                                    }}
                                                                />: 100€
                                                            </p>
                                                            <p>
                                                                <FormattedMessage
                                                                    id="during.prizes.stgo.pos2"
                                                                    values={{
                                                                        sup: (chunks: React.ReactNode) => (
                                                                            <sup>{chunks}</sup>
                                                                        ),
                                                                    }}
                                                                />: 75€
                                                            </p>
                                                            <p>
                                                                <FormattedMessage
                                                                    id="during.prizes.stgo.pos3"
                                                                    values={{
                                                                        sup: (chunks: React.ReactNode) => (
                                                                            <sup>{chunks}</sup>
                                                                        ),
                                                                    }}
                                                                />: 50€
                                                            </p>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="6">
                                            <Card className="shadow shadow-lg--hover mt-2">
                                                <CardBody>
                                                    <div className="d-flex px-1">
                                                        <div className="pl-2">
                                                            <h5 className="title text-warning">
                                                                {formatMessage({id: "during.prizes.others"})}
                                                            </h5>
                                                            <p>
                                                                {formatMessage({id: "during.prizes.others.desc"})}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card className="shadow shadow-lg--hover mt-2">
                                                <CardBody>
                                                    <div className="d-flex px-1">
                                                        <div className="pl-2">
                                                            <h5 className="title text-warning">
                                                                {formatMessage({id: "during.prizes.special"})}
                                                            </h5>
                                                            <ul>
                                                                <li>{formatMessage({id: "during.prizes.special.1"})}:
                                                                    100€
                                                                </li>
                                                                <li>{formatMessage({id: "during.prizes.special.2"})}:
                                                                    100€
                                                                </li>
                                                                <li>{formatMessage({id: "during.prizes.special.3"})}:
                                                                    100€
                                                                </li>
                                                                <li>{formatMessage({id: "during.prizes.special.4"})}:
                                                                    100€
                                                                </li>
                                                                <li>{formatMessage({id: "during.prizes.special.5"})}:
                                                                    100€
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <Col>
                                            <h3>{formatMessage({id: "during.prizes.terms"})}</h3>
                                            <p>{formatMessage({id: "during.prizes.terms.desc"})}</p>
                                            <ul>
                                                <li>{formatMessage({id: "during.prizes.terms.1"})}</li>
                                                <li><FormattedMessage
                                                    id="during.prizes.terms.2"
                                                    values={{
                                                        a: (chunks: React.ReactNode) => (
                                                            <a
                                                                href={"mailto:" + CONTACT}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {CONTACT}
                                                            </a>
                                                        ),
                                                    }}
                                                /></li>
                                                <li>{formatMessage({id: "during.prizes.terms.3"})}</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <img
                                                alt="..."
                                                className="img-fluid floating"
                                                src={require('../../assets/img/race/others/prizes.jpg')}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="4">
                                    <VerticalSponsors/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Prizes;
