interface PressItem {
    title: string,
    date: number,
    provider: string,
    source: string
}

export const Press: PressItem[] = [
    {
        title: "Moi pronto publicaremos máis info sobre a carreira!",
        date: 1577836800,
        provider: "sygo",
        source: "https://2020.santyago10k.es"
    }
];
