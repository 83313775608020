import { LanguageStrings } from './base-strings';

export const es: LanguageStrings = {
    /** Languages*/
    'lang': 'Idioma',
    'lang.ca': 'Galego',
    'lang.es': 'Español',
    'lang.en': 'English',

    /** Work In Progress */
    'wip.subtitle': 'Estamos trabajando en esta página. Mientras tanto, puedes echarle un vistazo a la antigua.',
    'wip.confirmation': 'Pendiente de confirmación',

    /** Routes */
    'routes.home': '/',
    'routes.covid19': '/covid19',
    'routes.before': '/antes',
    'routes.before.join': '/apuntarse',
    'routes.before.dorsal': '/antes/dorsales',
    'routes.before.getto': '/antes/llegar',
    'routes.before.parking': '/antes/aparcar',
    'routes.before.eating': '/antes/comer',
    'routes.before.chip': '/antes/chip',
    'routes.before.bag': '/antes/bolsa',
    'routes.during': '/durante',
    'routes.during.track': '/durante/recorrido',
    'routes.during.clothes': '/durante/guardarropa',
    'routes.during.drinking': '/durante/avituallamiento',
    'routes.during.showers': '/durante/duchas',
    'routes.during.prizes': '/durante/premios',
    'routes.after': '/despues',
    'routes.after.results': '/resultados',
    'routes.after.photos': '/fotos',
    'routes.after.dinner': '/despues/cenar',
    'routes.after.sleeping': '/despues/dormir',
    'routes.after.visiting': '/despues/visitar',
    'routes.info': '/otros',
    'routes.info.pastyears': '/anteriores',
    'routes.info.regulation': '/reglamento',
    'routes.info.terms': '/terminos',
    'routes.info.press': '/prensa',
    'routes.volunteer': '/voluntarios',
    'routes.contact': '/contacto',
    'routes.admin': '/admin',
    'routes.admin.calendar': '/admin/calendar',


    /** Page titles */
    'home.title': 'Inicio',
    'covid19.title': 'COVID-19',
    'before.title': 'El Antes',
    'before.join.title': 'Inscribirse',
    'before.dorsal.title': 'Recogida de dorsales',
    'before.getto.title': 'Como llegar',
    'before.parking.title': 'Donde aparcar',
    'before.eating.title': 'Donde comer',
    'before.chip.title': 'Como colocar el chip',
    'before.bag.title': 'Bolsa del corredor',
    'during.title': 'El Durante',
    'during.track.title': 'Recorrido',
    'during.clothes.title': 'Guardarropa',
    'during.drinking.title': 'Avituallamiento',
    'during.showers.title': 'Duchas',
    'during.prizes.title': 'Premios',
    'after.title': 'El Después',
    'after.results.title': 'Resultados',
    'after.photos.title': 'Fotos',
    'after.dinner.title': 'Donde cenar',
    'after.sleeping.title': 'Donde dormir',
    'after.visiting.title': 'Que visitar',
    'info.title': 'Info',
    'info.pastyears.title': 'Años Anteriores',
    'info.regulation.title': 'Reglamento',
    'info.terms.title': 'Términos y Condiciones',
    'info.press.title': 'Prensa',
    'volunteer.title': 'Voluntarios',
    'contact.title': 'Contacto',

    /** Navbar */
    'nav.before.getto': 'Una guía rápida sobre como llegar a la capital gallega',
    'nav.before.parking': 'Dónde dejar el coche en la carrera lo más barato posible',
    'nav.before.eating': 'Lugares para poder prepararse para la carrera',
    'nav.before.chip': 'Cómo colocar correctamente el chip en la zapatilla',
    'nav.before.bag': 'Qué va a contener la bolsa de los participantes',
    'nav.during.track': 'Todo el recorrido, en un mapa interactivo con altitudes',
    'nav.during.clothes': 'Puedes dejar tus pertenencias a salvo con nosotros',
    'nav.during.drinking': 'Dónde podrás reponer líquidos en la carrera',
    'nav.during.showers': 'Varias instalaciones a tu disposición para asearte',
    'nav.during.prizes': 'Al acabar habrá premios y sorteos para todos',
    'nav.after.dinner': 'Lugares para poder prepararse para la carrera',
    'nav.after.sleeping': 'Lugares para descansar tras la gran noche',
    'nav.after.visiting': 'Lugares interesantes para visitar en Santiago',

    /** Error */
    'error.404.heading': 'Página no encontrada',
    'error.404.subheading': 'Vaya, no hemos encontrado esta página. ¿Alomejor aparece en Inicio?',

    /** Footer */
    'footer.heading': "Carrera Nocturna SantYaGo10K",
    'footer.subheading': "Contacta con nosotros si tienes cualquier duda",

    /** Sponsors */
    'sponsors': "Patrocinan",
    'sponsors.organizers': "Organizan",
    'sponsors.partners': "Colaboran",

    /** Generic */
    'generic.moving.title': 'Cómo moverse por la ciudad',
    'generic.moving.subtitle': 'Santiago es una ciudad relativamente pequeña; podrás ir a cualquier lado caminando. ' +
        'Sino, siempre puedes coger un bus urbano o un taxi.',
    'generic.eatingdinner.title': 'Ñam Ñam',
    'generic.eatingdinner.subtitle': 'Galicia es famosa por su rica gastronomía. Y Santiago como buena capital gallega ' +
        'no iba a ser menos. En el casco antiguo se encuentra una gran variedad de restaurantes donde sirven comida ' +
        'típica gallega en abundancia.',
    'generic.eatingdinner.p1': 'Aprovecha el día de la carrera, vente a Santiago por la mañana, recoge tu dorsal y ' +
        'da una vuelta por la ciudad. Come en alguno de los resturantes de nuestros patrocinadores y aprovecha sus ofertas.',
    'generic.eatingdinner.p2': 'Recuerda además que la fiesta termina a medianoche, por lo que aquí dejamos unos ' +
        'restaurantes que se ajustan a cualquier tipo de ambiente que busques.',
    'generic.eatingdinner.p3': 'Todos estos colaboradores están esperando para ofrecerte, a ti y a tus ' +
        'acompañantes, una serie de ventajas por participar en la SantYaGo10K.',
    'generic.eatingdinner.open': 'Lunes-Domingo',
    'generic.eatingdinner.check': 'Mira en el mapa la situación de los sitios (desplegando el menú)',

    /** Home */
    'home.heading': '2 de Mayo a las 22:00',
    'home.subheading': '¡No te lo puedes perder!',
    'home.summary1': 'Corre por las emblemáticas zonas de la ciudad histórica de Santigo de Compostela. Un circuito ' +
        'nocturno por la zona vieja de la ciudad que nunca defrauda.',
    'home.summary2': 'Carrera contra el cáncer de pancreas',
    'home.track.heading': 'Nuestro circuito',
    'home.track.subheading': 'Con un desnivel máximo de sólo 53 metros',
    'home.track.description': 'Recuerda que el trazado será de 10 kilómetros e y está homologado por la Real Federación ' +
        'Española de Atletismo, con la garantía de que la medición del circuito es totalmente fiable. Así, tu ' +
        'marca será válida para otras competicións oficiais.',
    'home.track.more': 'Ver el circuito completo',
    'home.intro.title': 'Presentación',
    'home.intro.subtitle': 'Repasa el vídeo de la edición del 2017 para tener una idea de cuanto se disfruta en la SantYaGo10K.',
    'home.intro.l1': '6º Edición',
    'home.intro.l2': 'Para todas las edades',
    'home.intro.l3': 'Éxito asegurado',
    'home.join.price': 'Precio',
    'home.join.regular': 'Regular',
    'home.join.last': 'Última llamada',
    'home.join.not': 'Todavía no',
    'home.join.yes': 'Abierto',
    'home.join.closed': 'Cerrado',
    'home.join.discounts': 'Tenemos descuentos disponibles',
    'home.showers.description': 'Después de darlo todo, tocará picar algo por la zona. Pero antes una ducha para ' +
        'refrescarse, en las instalaciones del Complexo Deportivo Santa Isabel (justo al lado del parking) o ' +
        'en el Pabellón Municipal Lorenzo de la Torre (muy cerca del guardarropa).',
    'home.clothes.description': 'También disponemos de guardarropa en el Obradoiro. Queremos que solo pienses en ' +
        'pasarlo bien y correr sin preocupaciones.',
    'home.parking.description': 'El día de la prueba entre las 20:00 y las 3:00 podrás aparcar en el Parking de Rúa ' +
        'Galeras (Parking Catedral) por un coste total de 3€ por 7 horas de estacionamento.',
    'home.dorsal.description': 'Puedes recogerlo el día de la prueba de 10:00 a 21:00 en la Plaza del Obradoiro bajo ' +
        'los soportales del Concello.',
    'home.circuit.title': 'III Circuito de Carreras Ciudades Patrimonio de la Humanidad',
    'home.circuit.description': 'Sabemos que muchos de vosotros sois unos viajeros imparables. Por eso, como año tras ' +
        'año, la SantYaGo10k está dentro del "<a>Circuito de Carreras Ciudades Patrimonio de la Humanidad</a>".',
    'home.seemore': 'Ver más info',
    'home.covid19.title': 'Carrera Suspendida',
    'home.covid19.info': 'Debido a la situación actual relativa al COVID-19, nos es imposible garantizar al 100% la ' +
        'seguridad del evento, y preferimos no correr riesgos innecesarios.',
    'home.covid19.newdate': '¡Esperamos veros en la edición del 2021, que también es Año Xacobeo!',
    'home.covid19.seemore': 'Leer más',
    'home.covid19.close': 'Cerrar',
    'covid19.content': '<p>Debido a la situación de emergencia sanitaria actual, se ha decidido suspender la edición ' +
        'de este año de la SantYaGo10K. Nos es imposible garantizar la seguridad del evento, y como los corredores ' +
        'son nuestra prioridad, preferimos no correr riesgos innecesarios.</p><p>Sin embargo, esperamos poder veros ' +
        'a todos en la edición del 2021 (Año Xacobeo también), que seguro que será innolvidable.</p>',

    /** Join */
    'before.join.subtitle': '¡Estás muy cerca de formar parte de una noche muy especial! Sólo por inscribirte ya ' +
        'entrarás en muchos sorteos de regalos.',
    'before.join.now': '¡Apúntame ya!',
    'before.join.regular': 'Inscripción Regular',
    'before.join.regular.description': 'Para todos aquellos que os gusta planear las cosas con tiempo, podeis ' +
        'disfrutar de varias semanas para decidiros.',
    'before.join.lastcall': 'Inscripción In-Extremis',
    'before.join.lastcall.description': 'Para los que sois más de esperar hasta el último momento, no os vais a ' +
        'quedar fuera por ese motivo.',
    'before.join.not': 'Todavía no hemos abierto inscripciones; tienes arriba los plazos.',
    'before.join.important': 'Importante',
    'before.join.important.finish': 'Una inscripción sólo se considera completada cuando se hizo el pago',
    'before.join.important.price': 'El precio será el del momento de hacer el pago, no de cubrir los datos',
    'before.join.physical': 'Pago presencial',
    'before.join.physical.description': '<p>Si quieres hacer el pago con dinero en efectivo, en el formulario tienes que ' +
        'que marcar a casilla de "Solo quero facer unha preinscripcion" y en la siguiente pantalla no hacer el pago con ' +
        'tarjeta de crédito (no le des al botón).</p><p>Ahora sólo tienes que acercarte al Multiusos Fontes do Sar ou al ' +
        'CD Santa Isabel para hacer el pago y completar la inscripción.</p>',
    'before.join.discount': 'Descuentos',
    'before.join.discount.description': '<p>Los abonados al Multiusos Fontes do Sar o al CD Santa Isabel disfrutarán de' +
        'un descuento de 2€. Para aplicarlo, marcar la casilla "Solo quero facer unha preinscripcion" y realizar el ' +
        'pago presencial.</p><p>Los participantes del año pasado también disfrutarán de un descuento (se envió más ' +
        'información por correo electrónico).</p>',

    /** GetTo */
    'before.getto.subtitle': 'Santiago de Compostela: Ciudad Patrimonio de la Humanidad',
    'before.getto.heading': 'No te pierdas',
    'before.getto.subheading': 'A pesar de no ser una ciudad muy grande, sabemos que llegar a Santiago puede ' +
        'ser algo complicado. Por eso ponemos esta guía a vuestra disposición, para que los que venís de fuera lo ' +
        'tengáis un poco más fácil el asistir a la carrera.',
    'before.getto.car.title': 'En Coche',
    'before.getto.train.title': 'En Tren',
    'before.getto.plane.title': 'En Avión',
    'before.getto.car.description.p1': 'Echarle un vistazo a la información en <a>a</a> para ahorrar dinero si ' +
        'se llega en coche.',
    'before.getto.car.description.p2': 'Recuerda que cuando acabe la carrera hay un espectáculo que no te puedes ' +
        'perder y que, tal vez, te compense quedar hasta el domingo. Si ese es tu caso, mira las ofertas en ' +
        '<a>a</a>.',
    'before.getto.train.description': '<a>RENFE</a> ofrece muchas posibilidades de llegar en tren, con mucha frecuencia ' +
        'en algunas líneas.',
    'before.getto.plane.flight': 'Combinaciones de vuelos nacionales e internacionales',
    'before.getto.plane.taxi.commute': 'Transporte desde el aeropuerto a la ciudad',
    'before.getto.plane.bus': 'Autobús (<a>Empresa Freire</a>): Línea Aeroporto-Ciudad-Aeroporto',
    'before.getto.plane.bus.price': 'Ida: 3,00€ | Ida y vuelta: 5,10€',
    'before.getto.plane.bus.l1': 'Aeropuerto > Santiago: 07:00-01:00',
    'before.getto.plane.bus.l2': 'Santiago > Aeropuerto: 06:00-00:00',
    'before.getto.plane.bus.freq': 'cada 30 minutos',
    'before.getto.plane.taxi': 'Taxis (<a>Radio Taxi Compostela</a>)',
    'before.getto.plane.taxi.price': 'Aeropuerto-Santiago: 21,00€ aprox',
    'before.getto.plane.taxi.l1': 'Parada Taxis Aeropuerto: (+34) 981 591 575',
    'before.getto.plane.taxi.l2': 'Radio-Taxi: (+34) 981 56 92 92',

    /** Parking */
    'before.parking.subtitle': 'Si eres corredor, tenemos descuentos en aparcamiento en la ciudad. ¡No lo dejes pasar!',
    'before.parking.heading': 'No vas a tener problema',
    'before.parking.subheading': 'Santiago es una ciudad famoso por no tener muchas plazas de aparcamiento al aire libre. ' +
        'Por esto, recomendamos el siguiente parking en el que además tendréis una tarifa reducida considerablemente.',
    'before.parking.catedral.p1': 'El día de la carrera, entre las 20:00 del mismo día y las 03:00 del día siguiente, ' +
        'se podrá disfrutar de una tarifa reducida. <b>7 horas por tan solo 3€</b> (en caso de superar ese ' +
        'horario, se aplicará la tarifa normal para el resto).',
    'before.parking.catedral.p2': 'Para poder disfrutrarlo, el cliente deberá pasar por la Oficina de Control ' +
        '(y no pagar directamente en las máquinas), una vez estacionado o antes de abandonar las instalaciones y ' +
        'acreditar con el Dorsal que participa en la SantYaGo10K.',
    'before.parking.catedral.f1': '400 plazas de aparcamento disponibles para la carreira',
    'before.parking.catedral.f2': 'Abierto 24 horas',
    'before.parking.catedral.f3': 'A 200 metros de la catedral y del casco antiguo',
    'before.parking.catedral.f4': 'Custodia de bicicletas, motos y equipajes',
    'before.parking.catedral.f5': 'Servicio de alcoholímetros y préstamos de paraguas',
    'before.parking.catedral.f6': 'Plazas especiales para minusválidos y familias con carritos',
    'before.parking.catedral.f7': 'Wifi gratuíto para clientes',
    'before.parking.catedral.contact': 'Contacto',
    'before.parking.catedral.t1': 'El precio habitual de 7 horas de estacionamiento es de 11,10€, con la promoción 3,00€.',
    'before.parking.catedral.t2': 'Una vez excedido el tiempo en promoción, se aplicará la tarifa normal por minuto Parking.',

    /** Eating */
    'before.eating.subtitle': 'Antes de la carrera hay que coger fuerzas. Aquí os dejamos nuestras recomendaciones.',

    /** Chip */
    'before.chip.subtitle': 'Una carrera no es una carrera sin su debido cronometraje... Como la SantYaGo10K está ' +
        'homologada por la RFEA, usamos sistemas de medición de competición para garantizar los máximos estándares.',
    'before.chip.heading': 'Colocación del chip',
    'before.chip.subheading': 'En la <a>i</a> se entregará una bolsa con identificación, un <b>dorsal</b> y una <b>tarjeta ' +
        'de cronometraje</b> o <b>chip</b>. En la carrera se ha de usar esa tarjeta, sin servir ningún otro ' +
        'dispositivo de cronometraje que el corredor tenga en propiedad. Esa tarjeta identifica únicamente al ' +
        'número de dorsal que acompaña, siendo personal e intrasferible.',
    'before.chip.p1': 'El chip entregado será <b>retornable</b> por lo que es <b>muy importante</b> que, una vez ' +
        'finalizada la prueba, todos los participantes lo <b>devuelvan</b> en las zonas de recogida que se habilitarán en la Praza do ' +
        'Obradoiro. La no devolución e pérdida del chip identificativo supone un coste organizativo que será repercutido ' +
        'al titular del mesmo; rogamos vuestra colaboración en este aspecto.',
    'before.chip.p2': 'La tarjeta deberá ir colocada en los <b>cordones de la zapatilla</b>, o enganchada a ellos por ' +
        'medio de bridas. No se garantiza su funcionamiento si se porta por encima de los tobillos. ' +
        'Junto con el chip encontrareis <b>dos pequeños hilos de alambre</b>, que os ayudarán a atar el chip ' +
        'sin tener que sacar los cordones.',

    /** Bag */
    'before.bag.subtitle': 'Unos pocos regalitos para todos los corredores, para disfrutar todavía más si es posible' +
        'de la carrera.',
    'before.bag.heading': 'Para los corredores',
    'before.bag.subheading': 'Cuando acudas a la <a>i</a> se os entregará la bolsa del corredor. En ella encontrareis ' +
        'el dorsal (en el que va integrado el chip) y una serie de regalos como la camiseta oficial de la carrera.',

    /** Track */
    'during.track.subtitle': 'Un recorrido por el casco monumental de la capital gallega. ¿Qué más se puede pedir?',
    'during.track.circuit': 'El circuito',
    'during.track.circuit.p1': 'La extensión total del circuito es de 10.000 metros, 10 kms, y está homologado por la Real' +
        'Federación Española de Atletismo',
    'during.track.circuit.p2': 'En este plano se puede ver tanto el trazado como los puntos clave (salida, puntos kilométricos...), ' +
        'y lugares de interés (duchas, restaurantes, hostales...)',
    'during.track.circuit.p3': 'Puedes ocultar y mostrar el menú pulsando en este botón en el mapa: ',
    'during.track.start': 'Salida',
    'during.track.finish': 'Meta',
    'during.track.km2km': 'Kilómetro a Kilómetro',
    'during.track.alt': 'Altimetría',
    'during.track.alt.total': 'Distancia total',
    'during.track.alt.lowest': 'Punto más bajo',
    'during.track.alt.uphill': 'En subida',
    'during.track.alt.flat': 'En llano',
    'during.track.alt.highup': 'Subida más acusada',
    'during.track.alt.longup': 'Subida más longa',
    'during.track.alt.highdown': 'Bajada más acusada',
    'during.track.alt.longdown': 'Bajada más longa',
    'during.track.alt.totalud': 'Total Ascenso / Descenso',
    'during.track.alt.highest': 'Punto más alto',
    'during.track.alt.lowing': 'En descenso',
    'during.track.alt.gain': 'Ganancia en altura',
    'during.track.alt.meters': 'Desnivel en metros',
    'during.track.alt.percentage': 'Desnivel en porcentaje',

    /** Drinking */
    'during.drinking.subtitle': 'Todos los corredores dispondréis de vuestro debido avituallamiento. En resumen: ' +
        'tendréis a mitad del recorrido un refrigerio para reponer fuerzas.',
    'during.drinking.heading': 'Hidratarse es importantísimo',
    'during.drinking.subheading': 'Los corredores dispondrán de un punto de avituallamiento líquido con agua y bebida ' +
        'isotónica en el Paseo dos Leóns de la Alameda, justo en la mitad del recorrido.',
    'during.drinking.p1': 'Avituallamiento con la colaboración de nuestros patrocinadores:',
    'during.drinking.p2': 'Además, una vez cruzada la línea de meta, se dispondrá de un completo avituallamiento líquido ' +
        'y sólido en la Praza do Obradoiro, donde además podréis encontrar alguna sorpresa.',

    /** Clothes */
    'during.clothes.subtitle': 'Para que solo os preocupéis de correr, ponemos a vuestra disposición un servicio de ' +
        'guardarropa totalmente gratuito para los participantes.',
    'during.clothes.heading': 'A buen recaudo',
    'during.clothes.subheading1': 'Habilitaremos un guardarropa en los soportales del <b>Pazo de Raxoi</b>, en la <b>Praza do ' +
        'Obradoiro</b>, junto a la línea de meta y muy próximo a la línea de salida.',
    'during.clothes.subheading2': 'El servicio estará disponible desde las <b>20:30</b> hasta las <b>24:00</b>.',
    'during.clothes.p1': '<p>Pedimos que, si es posible, se traiga la bolsa/mochila con la pegatina identificativa ' +
        'con el número de dorsal. Esto nos facilita la tarea y agiliza la entrega y recogida de estos.</p><p>Aquí va un ' +
        'ejemplo de pegatina para usar en el ropero:</p>',
    'during.clothes.p2': 'Si no llevas mochila, te daremos una bolsita de plástico en la que podrás dejar tus ' +
        'pertenencias. Si tienes la pegatina se la pondremos, sino rotularemos tu número de dorsal y quedará ' +
        'gardada hasta que vayas a recogerla después de la carrera.',
    'during.clothes.p3': '<b>Será imprescindible que, tanto en la entrega como en la recogida, ' +
        'lleves contigo tu dorsal</b>. Sólo de esta manera te podremos devolver lo que nos dejes.',

    /** Showers */
    'during.showers.subtitle': 'Al acabar una carrera no hay nada mejor que darse una buena ducha después ' +
        'de todo el esfuerzo hecho. La SantYaGo10K pone de manera gratuita varias localizaciones para asearse.',
    'during.showers.heading': 'Donde ducharse',
    'during.showers.subheading': 'Una vez cruzada la línea de meta, posiblemente lo que más apetezca sea darse una ducha ' +
        'para asearse. Por ello, al acabar la carrera, encontraréis un completo servicio en dos localizaciones estratégicas ' +
        'para facilitar la logística.',
    'during.showers.time': 'Horario',
    'during.showers.address': 'Dirección',
    'during.showers.isabel': 'C.D. Santa Isabel',
    'during.showers.isabel.title': 'Piscina en el Complejo Deportivo Santa Isabel',
    'during.showers.isabel.p': 'Recordamos que tenemos un convenio con el Parking Catedral, que está situado muy cerca ' +
        'de estas instalaciones, por si se quiere dejar ahí el coche. <a>i</a>.',
    'during.showers.lorenzo': 'Pabellón Lorenzo de la Torre',
    'during.showers.lorenzo.title': 'Pabellón Municipal Lorenzo de la Torre',
    'during.showers.lorenzo.p': 'Estas duchas están prácticamente al lado de la Praza do Obradoiro, en la meta. ' +
        'Os encontraréis muy cerca del <a>i</a>.',

    /** Prizes */
    'during.prizes.subtitle': 'Todo esfuerzo tiene su recompensa. Repartimos muchísimos premios entre todos los ' +
        'participantes; ¡seguro que alguno te cae!',
    'during.prizes.heading': 'Intentamos repartir el máximo',
    'during.prizes.subheading': 'En el día de la carrera, tras la publicación de resultados en el tablero de anuncios, ' +
        'se hará la entrega de premios en el estrado instalado en la Praza do Obradoiro.',
    'during.prizes.p': 'Los atletas premiados deberán estar en la zona del pódium y estar atentos a su llamada en el ' +
        'momento que se haga el anuncio por megafonía. Será necesario presentar el DNI o pasaporte a efectos de ' +
        'identificación.',
    'during.prizes.masc': 'Categoría masculina',
    'during.prizes.fem': 'Categoría femenina',
    'during.prizes.pos1': '1<sup>a</sup> posición absoluta',
    'during.prizes.pos2': '2<sup>a</sup> posición absoluta',
    'during.prizes.pos3': '3<sup>a</sup> posición absoluta',
    'during.prizes.stgo.pos1': '1<sup>er</sup> empadronado',
    'during.prizes.stgo.pos2': '2<sup>do</sup> empadronado',
    'during.prizes.stgo.pos3': '3<sup>er</sup> empadronado',
    'during.prizes.others': 'Otras categorías',
    'during.prizes.others.desc': 'También obtendrán trofeo los tres primeros clasificados masculino y femenino en cada ' +
        'categoría',
    'during.prizes.special': 'Categorías especiles',
    'during.prizes.special.1': 'Club Federado con más participantes llegados a la meta',
    'during.prizes.special.2': 'Grupo No Federado con más participantes llegados a la meta',
    'during.prizes.special.3': 'Grupo No Federado con más participantes femeninas llegados a la meta',
    'during.prizes.special.4': 'Club o Grupo que proceda desde más lejos',
    'during.prizes.special.5': 'Premio especial “Cándido Calvo”, para el corredor de mayor edad',
    'during.prizes.terms': 'Reglas',
    'during.prizes.terms.desc': 'Para poder optar a premios es necesario saber que:',
    'during.prizes.terms.1': 'Los premios no serán acumulables.',
    'during.prizes.terms.2': 'Para poder optar a premio por Club Federado o Grupo No Federado, los atletas deberán ' +
        'realizar su inscripción individualmente y posteriormente deberán enviar por correo electrónico a la dirección ' +
        '<a>i</a> un listado detallando los miembros computabes al efecto.',
    'during.prizes.terms.3': 'En caso de empate entre clubs/grupos, se premiará al que menor suma total de tiempos ' +
        'tenga al acabar la prueba, igualmente para los corredores de mayor edad.',

    /** Dinner */
    'after.dinner.subtitle': 'Al acabar la carrera hay que reponer fuerzas. Aquí os dejamos nuestras recomendaciones.',

    /** Sleeping */
    'after.sleeping.subtitle': '¿Por qué no aprovechar ahora que ya estás en Santiago y pasar unos días de visita?',
    'after.sleeping.heading': 'Alojamientos recomendados',
    'after.sleeping.subheading': 'Te ayudamos a buscar alojamiento para que no tengas que viajar de noche, no ' +
        'te pierdas el fin de fiesta que se está preparando. Quédate a vivir la magia de las noches compostelanas.',
    'after.sleeping.p': 'Estos son los hoteles que colaboran con nosotros y ofrecen precios especiales, hay alternativas para ' +
        'todos los bolsillos.',
    'after.sleeping.check': 'Mira en el mapa la situación de estos lugares (desplegando el menú)',

    /** Visiting */
    'after.visiting.subtitle': 'Santiago es una Ciudad Patrimonio de la Humanidad. Y, como tal, tiene centenares de ' +
        'sitios turísticos para visitar.',
    'after.visiting.heading': 'Turismo por Santiago',
    'after.visiting.p1': 'Nuestros amigos de la Oficina de Turismo de Santiago de Compostela dan algunos ' +
        'consejos para que podáis aprovechar de la estancia en Santiago. Además, podéis encontrar propuestas ' +
        'más interesantes en función de los días de los que dispongais.',
    'after.visiting.p2': 'Y para ir abriendo bocas, algunos vídeos de lo que se puede hacer en Santiago.',

    /** Voluntarios */
    'volunteer.subtitle': 'Forma parte de la familia SantYaGo10K',
    'volunteer.heading': 'Porque esto es más que una carrera',
    'volunteer.p1': 'La preparación de la SantYaGo10K es una carrera de fondo (perdonad por el chiste). Un gran equipo ' +
        'está detrás de todo desde hace meses, para que todo salga perfecto y solo haya que disfrutar en el día clave.',
    'volunteer.p2': 'Pero no somos suficientes. Queremos pedir vuestra colaboración, ya que la presencia de ' +
        'voluntarios es algo fundamental para un evento de tal magnitud. ¿Tienes a algún familiar o amigo corredor? ' +
        'Apóyale siendo voluntario en la carrera. ¡Cuantos más seamos mejor!',
    'volunteer.a1': '¡Únete a nosotros el día 2 de Mayo, y colabora con la organización de la carreira como voluntario!',
    'volunteer.closed': 'Todavía no están abiertas las inscripciones para voluntarios',
    'volunteer.p3': 'Solo tienes que introducir tus datos en el este formulario. Además, tendrás merienda y regalos.',
    'volunteer.a2': 'Los <b>menores de edad</b> deberán remitirnos un documento de consentimento firmado por los ' +
        'padres/madres/titores que podrán descargar desde <a>aquí</a>.',
    'volunteer.a3': 'Los <b>universitarios</b> podrán solicitar créditos a la USC a través del Servicio de ' +
        'Participación e Integración Universitaria.',

    /** Past Years */
    'info.pastyears.heading': 'Ediciones anteriores',
    'info.pastyears.subheading': 'Descubre el origen de esta gran carrera',
    'info.pastyears.more': 'Leer más',
    'info.pastyears.year': 'Año',
    'info.pastyears.ed.1': '1º Edición',
    'info.pastyears.ed.2': '2º Edición',
    'info.pastyears.ed.3': '3º Edición',
    'info.pastyears.ed.4': '4º Edición',
    'info.pastyears.ed.5': '5º Edición',
    'info.pastyears.origin': 'Origen',
    'info.pastyears.origins': '<p>' +
        'Esta carrera nace como un proyecto del Club de Atletismo Fontes do Sar y del Multiusos Fontes do Sar – ' +
        'Complexo Deportivo Santa Isabel, con intención de traer a la ciudad de Santiago de Compostela una Carrera ' +
        'Nocturna Homologada.</p>' +
        '<p>Hemos trabajado con idea de ofrecer un recorrido que permita disfrutar del encanto del casco histórico ' +
        'de la ciudad a los corredores populares, locales o foráneos, y al mismo tiempo ofrecer un trazado lo ' +
        'más llano posible y que permita disputar la carrera a aquellos que hacen del atletismo un modo de vida.</p>' +
        '<p>Afortunadamente contamos con la promoción del Concello de Santiago a través de varias concejalías, entre ' +
        'ellas la Concejalía de Servicios Sociales por medio del Departamento Municipal de Deportes del Concello ' +
        'de Santiago o la Concejalía de Economía, en lo tocante al Turismo en Santiago</p>' +
        '<p>Nuestra intención es iniciar y consolidar un evento anual; buscamos convertirlo en un referente ' +
        'deportivo y turístico al igual que otras competiciones de alto nivel desarrolladas en otras ciudades ' +
        'de Galicia (Coruña, Vigo, Ourense, etc), pero ante todo y sobre todo esperamos que… DISFRUTÉIS CORRIENDO!</p>',

    /** Regulation */
    'info.regulation.subtitle': 'Reglamiento de la Carrera Nocturna SantYaGo10K',
    'info.regulation.galician': 'Gallego',

    /** Terms */
    'info.terms.subtitle': 'Términos y Condiciones de uso de la página web',

    /** Press */
    'info.press.subtitle': 'Aquí iremos enlazando la cobertura de medios de comunicación sobre la carrera.',
    'info.press.read': 'Leer noticia',

    /** Contact */
    'contact.heading': '¿Tienes preguntas?',
    'contact.subheading': 'Envíanos un correo y te contestaremos lo antes posible.',
    'contact.subject': 'Asunto del mensaje',
    'contact.message': 'Mensaje...',
    'contact.send': 'Enviar',
};
