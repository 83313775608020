import React from "react";

import {Row, Col, CardBody, Card, Badge} from "reactstrap";
import {FormattedMessage} from 'react-intl';

export const EatingDinner: React.FC = () => (
    <>
        <Badge color="warning" pill className="mr-1">
            <FormattedMessage id="wip.confirmation"/>
        </Badge>
        <h1><FormattedMessage id="generic.eatingdinner.title"/></h1>
        <Row>
            <Col lg="6">
                <p><FormattedMessage id="generic.eatingdinner.subtitle"/></p>
            </Col>
            <Col lg="6">
                <img
                    alt="..."
                    className="img-fluid"
                    src={require('../../../assets/img/undraw/dinner.png')}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <p><FormattedMessage id="generic.eatingdinner.p1"/></p>
                <p><FormattedMessage id="generic.eatingdinner.p2"/></p>
                <p><FormattedMessage id="generic.eatingdinner.p3"/></p>
            </Col>
        </Row>
        <Row>
            <Col lg="6">
                <Card className="shadow shadow-sm mt-5">
                    <CardBody>
                        <div className="d-flex px-3">
                            <div className="pl-4">
                                <h5 className="title text-warning">
                                    Resturante Galeón do Toural
                                </h5>
                                <p>Cantón do Toural, 4<br/>15705 - Santiago de Compostela</p>
                                <p><span className="fa fa-calendar"/> <FormattedMessage
                                    id="generic.eatingdinner.open"/> 10:00-2:00</p>
                                <p><span className="fa fa-phone"/> (+34) 981 58 61 40</p>
                                <p><span className="fa fa-globe"/> <a
                                    href="http://galeontoural.com/" target="_blank"
                                    rel="noopener noreferrer">galeontoural.com</a></p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg="6">
                <Card className="shadow shadow-sm mt-5">
                    <CardBody>
                        <div className="d-flex px-3">
                            <div className="pl-4">
                                <h5 className="title text-warning">
                                    Restaurante Caminante
                                </h5>
                                <p>Rúa de Ceuta, 98<br/>15707 - Santiago de Compostela</p>
                                <p><span className="fa fa-calendar"/> <FormattedMessage
                                    id="generic.eatingdinner.open"/> 08:00-00:00</p>
                                <p><span className="fa fa-phone"/> (+34) 696 87 61 40</p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <Row className="mt-5">
            <Col>
                <h3><FormattedMessage id="generic.eatingdinner.check"/></h3>
                <Card className="shadow border-0">
                    <CardBody className="py-0 px-0 pr-0 pl-0 pt-0 pb-0" style={{height: 512}}>
                        <iframe
                            title="map"
                            src="https://www.google.com/maps/d/u/0/embed?mid=1WmQ7-W6d06_uswEl11U7Z5vLMkNK66Tm&z=14"
                            width="100%"
                            height="512"
                            style={{border: "none"}}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
);
