import React from 'react';
import {Footer, Navigation, OneSignal} from '../../navigation';

const NO_REDIR_STORAGE_KEY = "NO_REDIR";
const NO_REDIR_STORAGE_VALUE = "1";

export const AppLayout: React.FC = ({children}) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  if (sessionStorage.getItem(NO_REDIR_STORAGE_KEY) !== NO_REDIR_STORAGE_VALUE) {
    if (urlParams.has("no_redir")) {
      sessionStorage.setItem(NO_REDIR_STORAGE_KEY, NO_REDIR_STORAGE_VALUE);
    } else {
      localStorage.removeItem("2022");
      window.location.replace("https://www.santyago10k.run");
    }
  }

  return <div>
    <OneSignal/>
    <Navigation/>
    {children}
    <Footer/>
  </div>;
};
