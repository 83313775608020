import React, {useState} from "react";
import {Helmet} from 'react-helmet';

import {
    Container,
    Row,
    Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane,
} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {Moving, VerticalSponsors} from "../../modules/layout";
import classnames from "classnames";
import {Link} from "react-router-dom";
import {Route} from "../../modules/i18n";
import {AppRoute, AppRouteTitles} from "../../const";


export const GetTo: React.FC = () => {
    const {formatMessage} = useIntl();

    const [state, setState] = useState("i");
    let toggleNavs = (e: any, k: string) => {
        e.preventDefault();
        setState(k);
    };

    React.useEffect(() => {
        if (state === "i") {
            document.documentElement.scrollTop = 0;
            if (document.scrollingElement !== null)
                document.scrollingElement.scrollTop = 0;
            setState("car");
        }
    }, [state]);

    let parking = Route(AppRoute.Parking);
    let sleep = Route(AppRoute.Sleeping);

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "before.getto.title"})} | SantYaGo10K 2020</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped">
                        <div className="shape shape-style-1 bg-gradient-blue">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "before.getto.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "before.getto.subtitle"})}
                                        </p>
                                    </Col>
                                    <Col lg="6">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require('../../assets/img/race/pages/getto.jpg')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section section-sm">
                        <Container>
                            <Row>
                                <Col lg="8">
                                    <h1>{formatMessage({id: "before.getto.heading"})}</h1>
                                    <Row>
                                        <Col lg="6">
                                            <p>{formatMessage({id: "before.getto.subheading"})}</p>
                                        </Col>
                                        <Col lg="6">
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../assets/img/undraw/getto.png")}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="nav-wrapper">
                                                <Nav
                                                    className="nav-fill flex-column flex-md-row"
                                                    id="tabs-icons-text"
                                                    pills
                                                    role="tablist"
                                                >
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={state === "car"}
                                                            className={classnames("mb-sm-3 mb-md-0", {
                                                                active: state === "car"
                                                            })}
                                                            onClick={e => toggleNavs(e, "car")} href="#"
                                                            role="tab"
                                                        >
                                                            <i className="fa fa-car"/> {formatMessage({id: "before.getto.car.title"})}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={state === "train"}
                                                            className={classnames("mb-sm-3 mb-md-0", {
                                                                active: state === "train"
                                                            })}
                                                            onClick={e => toggleNavs(e, "train")} href="#"
                                                            role="tab"
                                                        >
                                                            <i className="fa fa-train"/> {formatMessage({id: "before.getto.train.title"})}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={state === "plane"}
                                                            className={classnames("mb-sm-3 mb-md-0", {
                                                                active: state === "plane"
                                                            })}
                                                            onClick={e => toggleNavs(e, "plane")} href="#"
                                                            role="tab"
                                                        >
                                                            <i className="fa fa-plane"/> {formatMessage({id: "before.getto.plane.title"})}
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </div>
                                            <Card className="shadow">
                                                <CardBody>
                                                    <TabContent activeTab={"t" + state}>
                                                        <TabPane tabId="tcar">
                                                            <h6>
                                                                <FormattedMessage
                                                                    id="before.getto.car.description.p1"
                                                                    values={{
                                                                        a: () => (
                                                                            <Link to={parking}>
                                                                                {formatMessage({id: AppRouteTitles.get(AppRoute.Parking)})}
                                                                            </Link>
                                                                        ),
                                                                    }}
                                                                />
                                                            </h6>
                                                            <h6>
                                                                <FormattedMessage
                                                                    id="before.getto.car.description.p2"
                                                                    values={{
                                                                        a: () => (
                                                                            <Link to={sleep}>
                                                                                {formatMessage({id: AppRouteTitles.get(AppRoute.Sleeping)})}
                                                                            </Link>
                                                                        ),
                                                                    }}
                                                                />
                                                            </h6>
                                                        </TabPane>
                                                        <TabPane tabId="ttrain">
                                                            <h6>
                                                                <FormattedMessage
                                                                    id="before.getto.train.description"
                                                                    values={{
                                                                        a: (chunks: React.ReactNode) => (
                                                                            <a href="http://renfe.es" target="_blank"
                                                                               rel="noopener noreferrer">{chunks}</a>
                                                                        ),
                                                                    }}
                                                                />
                                                            </h6>
                                                            <img
                                                                alt="..."
                                                                style={{maxHeight: 100}}
                                                                className="img-fluid rounded"
                                                                src={require('../../assets/img/others/renfe.png')}
                                                            />
                                                        </TabPane>
                                                        <TabPane tabId="tplane" style={{lineHeight: 30}}>
                                                            <h6>
                                                                <b>{formatMessage({id: "before.getto.plane.flight"})}</b>
                                                            </h6>
                                                            <h6>
                                                                <b>{formatMessage({id: "before.getto.plane.taxi.commute"})}</b>
                                                                <ul>
                                                                    <li>
                                                                        <FormattedMessage
                                                                            id="before.getto.plane.bus"
                                                                            values={{
                                                                                a: (chunks: React.ReactNode) => (
                                                                                    <a href="https://www.empresafreire.com"
                                                                                       target="_blank"
                                                                                       rel="noopener noreferrer">{chunks}</a>
                                                                                ),
                                                                            }}
                                                                        />
                                                                        <br/>
                                                                        <small>{formatMessage({id: "before.plane.getto.bus.price"})}</small>
                                                                        <ul>
                                                                            <li>{formatMessage({id: "before.plane.getto.bus.l1"})}
                                                                                <small>{formatMessage({id: "before.getto.plane.bus.freq"})}</small>
                                                                            </li>
                                                                            <li>{formatMessage({id: "before.plane.getto.bus.l2"})}
                                                                                <small>{formatMessage({id: "before.getto.plane.bus.freq"})}</small>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        <FormattedMessage
                                                                            id="before.getto.plane.taxi"
                                                                            values={{
                                                                                a: (chunks: React.ReactNode) => (
                                                                                    <a href="https://www.radiotaxicompostela.com"
                                                                                       target="_blank"
                                                                                       rel="noopener noreferrer">{chunks}</a>
                                                                                ),
                                                                            }}
                                                                        />
                                                                        <br/>
                                                                        <small>{formatMessage({id: "before.getto.plane.taxi.price"})}</small>
                                                                        <ul>
                                                                            <li>{formatMessage({id: "before.getto.plane.taxi.l1"})}</li>
                                                                            <li>{formatMessage({id: "before.getto.plane.taxi.l2"})}</li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </h6>
                                                        </TabPane>
                                                    </TabContent>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Moving/>
                                </Col>
                                <Col lg="4">
                                    <VerticalSponsors/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default GetTo;
