import {LanguageStrings} from './base-strings';

export const en: LanguageStrings = {
    /** Languages*/
    'lang': 'Language',
    'lang.ca': 'Galego',
    'lang.es': 'Español',
    'lang.en': 'English',

    /** Work In Progress */
    'wip.subtitle': 'We are working on this page. In the meantime, you can check below the legacy one.',
    'wip.confirmation': 'Pending confirmation',

    /** Routes */
    'routes.home': '/',
    'routes.covid19': '/covid19',
    'routes.before': '/before',
    'routes.before.join': '/join',
    'routes.before.dorsal': '/before/dorsal',
    'routes.before.getto': '/before/getto',
    'routes.before.parking': '/before/parking',
    'routes.before.eating': '/before/eating',
    'routes.before.chip': '/before/chip',
    'routes.before.bag': '/before/bag',
    'routes.during': '/during',
    'routes.during.track': '/during/track',
    'routes.during.clothes': '/during/clothes',
    'routes.during.drinking': '/during/drinking',
    'routes.during.showers': '/during/showers',
    'routes.during.prizes': '/during/prizes',
    'routes.after': '/after',
    'routes.after.results': '/results',
    'routes.after.photos': '/photos',
    'routes.after.dinner': '/after/dinner',
    'routes.after.sleeping': '/after/sleeping',
    'routes.after.visiting': '/after/visiting',
    'routes.info': '/info',
    'routes.info.pastyears': '/past',
    'routes.info.regulation': '/regulation',
    'routes.info.terms': '/terms',
    'routes.info.press': '/press',
    'routes.volunteer': '/volunteer',
    'routes.contact': '/contact',
    'routes.admin': '/admin',
    'routes.admin.calendar': '/admin/calendar',

    /** Error */
    'error.404.heading': 'Page Not Found',
    'error.404.subheading': 'Sorry, but we could not find this page. Maybe you miss-spelled it?',

    /** Page titles */
    'home.title': 'Home',
    'covid19.title': 'COVID-19',
    'before.title': 'The Before',
    'before.join.title': 'Run it!',
    'before.dorsal.title': 'Dorsal pick-up',
    'before.getto.title': 'How to get here',
    'before.parking.title': 'Where to park',
    'before.eating.title': 'Where to eat',
    'before.chip.title': 'How to use the chip',
    'before.bag.title': 'Runner bag',
    'during.title': 'The Race',
    'during.track.title': 'Track',
    'during.clothes.title': 'Wardrobe',
    'during.drinking.title': 'Drinking',
    'during.showers.title': 'Showers',
    'during.prizes.title': 'Prizes',
    'after.title': 'The After',
    'after.results.title': 'Results',
    'after.photos.title': 'Photos',
    'after.dinner.title': 'Where to have dinner',
    'after.sleeping.title': 'Where to sleep',
    'after.visiting.title': 'What to visit',
    'info.title': 'Info',
    'info.pastyears.title': 'Past Years',
    'info.regulation.title': 'Regulation',
    'info.terms.title': 'Terms and Conditions',
    'info.press.title': 'Press',
    'volunteer.title': 'Volunteer',
    'contact.title': 'Contact',

    /** Navbar */
    'nav.before.getto': 'A quick guide to get into the Galician capital',
    'nav.before.parking': 'Where to leave the car as cheap as possible',
    'nav.before.eating': 'Places to get some food for the race',
    'nav.before.chip': 'How to properly put the chip',
    'nav.before.bag': 'What is inside the free runner bag',
    'nav.during.track': 'Info about the track, including altitudes',
    'nav.during.clothes': 'Where to leave your clothes while running',
    'nav.during.drinking': 'Where to get water during race',
    'nav.during.showers': 'Places to get a fresh shower',
    'nav.during.prizes': 'At the end there will be draws and awards',
    'nav.after.dinner': 'Places to get a nice dinner',
    'nav.after.sleeping': 'Places to sleep as a king',
    'nav.after.visiting': 'Cool places to visit while in Santiago',

    /** Footer */
    'footer.heading': "Night Race SantYaGo10K",
    'footer.subheading': "Contact us if you have any question",

    /** Sponsors */
    'sponsors': "Sponsoring",
    'sponsors.organizers': "Organizing",
    'sponsors.partners': "Cooperating",

    /** Generic */
    'generic.moving.title': 'How to move in the city',
    'generic.moving.subtitle': 'Santiago is a quite small city; you can go anywhere just by walking a bit. ' +
        'Either way, you can always take an urban/metropolitan bus or a taxi.',
    'generic.eatingdinner.title': 'Yum Yum',
    'generic.eatingdinner.subtitle': 'Galicia is famous for its gastronomy. And Santiago, as capital, was not be ' +
        'less. In the old city you can find lots of nice resturants that will serve ' +
        'typical food in large amounts.',
    'generic.eatingdinner.p1': 'Seize the race day, arrive by morning, grab your dorsal and ' +
        'take a walk around the city. Have lunch at some of our partners\' resturants and enjoy the discounts.',
    'generic.eatingdinner.p2': 'Don\'t forget that party will be over at midnight, so here you have a list of ' +
        'restaurants that will fit with any kind of personality.',
    'generic.eatingdinner.p3': 'All these partners are eagerly waiting to serve, both you and your colleges, ' +
        'lots of advantages just because of taking part in the SantYaGo10K.',
    'generic.eatingdinner.open': 'Monday-Sunday',
    'generic.eatingdinner.check': 'Check in the map the locations (by opening the menu)',

    /** Home */
    'home.heading': '2<sup>nd</sup> May @ 22:00',
    'home.subheading': 'You cannot miss it!',
    'home.summary1': 'Run through the historical city of Santiago de Compostela. A night circuit in the old town ' +
        'that never lets you down.',
    'home.summary2': 'Race against pancreatic cancer',
    'home.track.heading': 'Our track',
    'home.track.subheading': 'With a maximum slope of just 53 meters',
    'home.track.description': 'Remember that this track length is 10 kilometers, and it is approved by the Royal ' +
        'Spanish Athletics Federation, which guarantees the highest measurement standards. Your mark will be valid ' +
        'for other official athletics competitions.',
    'home.track.more': 'Check the complete track',
    'home.intro.title': 'Introduction',
    'home.intro.subtitle': 'Check the 2017\'s edition to see how enjoyable the SantYaGo10K is.',
    'home.intro.l1': '6<sup>th</sup> Edition',
    'home.intro.l2': 'For all ages',
    'home.intro.l3': 'Guaranteed success',
    'home.join.price': 'Price',
    'home.join.regular': 'Regular',
    'home.join.last': 'Last call',
    'home.join.not': 'Not yet',
    'home.join.yes': 'Open',
    'home.join.closed': 'Closed',
    'home.join.discounts': 'Discounts are available',
    'home.showers.description': 'After all efforts, it is time to get some snacks. But better take a fresh shower ' +
        'before going, in the Sports Facilities Santa Isabel (just next to the parking) or ' +
        'in the Pavilion Lorenzo de la Torre (quite close to the wardrobe).',
    'home.clothes.description': 'We also offer a wardrobe service in the Obradoiro. Just think about enjoying the ' +
        'run  and without worries.',
    'home.parking.description': 'The race day, between 20:00 and 3:00, you can park your card in a parking at Rúa ' +
        'Galeras (Parking Catedral) at just 3€ for the 7 hours of parking.',
    'home.dorsal.description': 'You can take it on the race day from 10:00 to 21:00 in Plaza do Obradoiro below ' +
        'the Townhall.',
    'home.circuit.title': 'III Race Circuit World Heritage Cities',
    'home.circuit.description': 'We know most of you are unstoppable travelers. Thus, as it is being year by year, ' +
        'the SantYaGo10K track is part of the "<a>Race Circuit World Heritage Cities</a>".',
    'home.seemore': 'See more',
    'home.covid19.title': 'Suspended Race',
    'home.covid19.info': 'Due to the current situation related with COVID-19, it is impopssible to guarantee the ' +
        'entire event security, and there is no need to take unnecessary risks.',
    'home.covid19.newdate': 'We hope to see you soon in next year\'s edition!',
    'home.covid19.seemore': 'Read more',
    'home.covid19.close': 'Close',
    'covid19.content': '<p>Due to the current health emergency situation, it has been decided to suspend this ' +
        'year\'s edition of the SantYaGo10K. It is impossible for us to guarantee the safety of the event, and as ' +
        'the runners are our priority, we prefer not to take unnecessary risks.</p><p>However, we hope to see you ' +
        'all in the 2021 edition (Jacobean Year as well), which will surely be unforgettable.</p>',

    /** Join */
    'before.join.subtitle': 'Just one more step to be in this unforgettable night! Just with your registration, you ' +
        'will be eligible for lots of prize draws.',
    'before.join.now': 'Let\'s do it now!',
    'before.join.regular': 'Regular Registration',
    'before.join.regular.description': 'For all the ones that like to plan everything with time, you can do it ' +
        'within several weeks without hurries.',
    'before.join.lastcall': 'Last-Call Registration',
    'before.join.lastcall.description': 'For the other ones that prefer to do everything in the last-minute, ' +
        'you are not getting out due to that.',
    'before.join.not': 'We have not yet opened registrations; you can find the timeline above.',
    'before.join.important': 'Important',
    'before.join.important.finish': 'A registration is only considered as finished once the payment has been completed',
    'before.join.important.price': 'The price will be the one for the payment date, not the form filling one',
    'before.join.physical': 'On-site Payment',
    'before.join.physical.description': '<p>If you prefer to pay with cash, when filling the form mark the "Solo quero ' +
        'facer unha preinscripcion" checkbox and don\'t make the credit card payment (just do\'t click the button).' +
        '</p><p>Now just head to Multiusos or to SC Santa Isabel to make the payment and finish the registration.</p>',
    'before.join.discount': 'Discounts',
    'before.join.discount.description': '<p>Subscribers to Multiusos Fontes do Sar and SC Santa Isabel will enjoy a 2€ ' +
        'discount in the registration. To apply it, mark the "Solo quero facer unha preinscripcion" checkbox and make ' +
        'an on-site payment.</p><p>Last year participants will also enjoy a discount (more information has been ' +
        'provided by email).</p>',

    /** GetTo */
    'before.getto.subtitle': 'Santiago de Compostela: World Heritage City',
    'before.getto.heading': 'Don\'t get lost',
    'before.getto.subheading': 'Even though it is not a very big city, we know getting here can be a bit ' +
        'hard. Thus, we have created this quick guide for the ones who come from outside so you will ' +
        'find easier to assist to the race.',
    'before.getto.car.title': 'By Car',
    'before.getto.train.title': 'By Train',
    'before.getto.plane.title': 'By Plane',
    'before.getto.car.description.p1': 'Check the information at <a>a</a> so you can save money if you ' +
        'get here by car.',
    'before.getto.car.description.p2': 'Remember there is also an event at the end of the race which you cannot ' +
        'miss and that, maybe, you will find worthy to stay until Sunday. If this is your case, check some offers at ' +
        '<a>a</a>.',
    'before.getto.train.description': '<a>RENFE</a> offers lots of choices to arrive by train, with some highly-frequent ' +
        'lines.',
    'before.getto.plane.flight': 'National and International flights',
    'before.getto.plane.taxi.commute': 'Commute between airport and city',
    'before.getto.plane.bus': 'Bus (<a>Freire\'s Company</a>): Airport-City-Airport Line',
    'before.getto.plane.bus.price': 'One-Way: 3,00€ | Return: 5,10€',
    'before.getto.plane.bus.l1': 'Airport > Santiago: 07:00-01:00',
    'before.getto.plane.bus.l2': 'Santiago > Airport: 06:00-00:00',
    'before.getto.plane.bus.freq': 'every 30 minutes',
    'before.getto.plane.taxi': 'Taxis (<a>Radio Taxi Compostela</a>)',
    'before.getto.plane.taxi.price': 'Airport-Santiago: 21,00€ aprox',
    'before.getto.plane.taxi.l1': 'Taxi Stop Airport: (+34) 981 591 575',
    'before.getto.plane.taxi.l2': 'Radio-Taxi: (+34) 981 56 92 92',

    /** Parking */
    'before.parking.subtitle': 'If you are running the race, we have discounts in the city. Are you going to miss them?',
    'before.parking.heading': 'You won\'t have any issue',
    'before.parking.subheading': 'Santiago is a famous city because of the small amount of in-city parking spots. ' +
        'Thus, we recommend the following parking on which you will get a quite big discount.',
    'before.parking.catedral.p1': 'The race day, from 20:00 to 03:00 of next day, ' +
        'you can get a reduced price. <b>7 hours at just 3€</b> (if you need more time ' +
        'you will be charged the extra minuteswith the normal rate).',
    'before.parking.catedral.p2': 'If you want it, you have to first go to the Control Office ' +
        '(and don\'t directly pay in the pay-machines), just after arriving or before leaving. You will need ' +
        'to show your dorsal as proof of assistance to the SantYaGo10K.',
    'before.parking.catedral.f1': '400 parking spots available for the race',
    'before.parking.catedral.f2': 'Open 24 hours',
    'before.parking.catedral.f3': '200 meters away from cathedral and historical city',
    'before.parking.catedral.f4': 'Safeguard of bicycles, motorbikes and baggage',
    'before.parking.catedral.f5': 'Breathalyzer service and umbrella loans',
    'before.parking.catedral.f6': 'Special spots for handicapped and families with babies',
    'before.parking.catedral.f7': 'Free WiFi for clients',
    'before.parking.catedral.contact': 'Contact',
    'before.parking.catedral.t1': 'The normal price of the 7 hours is 11,10€, with the promo goes to 3,00€.',
    'before.parking.catedral.t2': 'Once exceeded the time of the promo, the extra minutes will be rated with the ' +
        'normal minute-based tarif.',

    /** Eating */
    'before.eating.subtitle': 'Before the race you have to gain strength. Here are our recommendations.',

    /** Chip */
    'before.chip.subtitle': 'A race is not a race without the appropriate timing tracking... As the SantYaGo10K is a ' +
        'certified race by the RFEA, we use competition systems to ensure the highest measurement standards.',
    'before.chip.heading': 'Chip placement',
    'before.chip.subheading': 'In the <a>i</a>, you will get a bag with identification, a <b>dorsal</b> and a <b>tracking ' +
        'card</b> or <b>chip</b>. You will have to use this chip in the race, as other personal tracking devices ' +
        'will not be used for official records. This card only identifies the dorsal number of the runner ' +
        'being totally personal and non-transferable.',
    'before.chip.p1': 'The given chip is <b>returnable</b>, which makes <b>very important</b> having to ' +
        'return it once the race finishes. We ask all runners to <b>give it back</b> in the marked zones at Praza do ' +
        'Obradoiro. The non-returning of the chip means an organizing cost which will be reflected to the chip owner ' +
        'itself; we kindly ask your co-operation with this.',
    'before.chip.p2': 'Chip shall be placed in the <b>shoelaces</b>, or linked to them with bridles. ' +
        'Its correct function is not guaranteed if placed above ankles. With ' +
        'the chip you will find <b>two little wire strands</b> that will help you place it without giving back laces.',

    /** Bag */
    'before.bag.subtitle': 'A few gifts for all runners, to enjoy even more, if possible, the race day.',
    'before.bag.heading': 'For runners',
    'before.bag.subheading': 'When you go to the <a>i</a> you will get the runner\'s bag. You will find the ' +
        'dorsal (which has the integrated tracking chip), and a few gifts from sponsors, including the official race T-shirt.',

    /** Track */
    'during.track.subtitle': 'A track though the monumental town in the capital of Galicia. What else can we ask for?',
    'during.track.circuit': 'The track',
    'during.track.circuit.p1': 'The total length of the circuit is 10,000 meters, 10 kms, and it is approved by the Royal' +
        'Spanish Athletics Federation',
    'during.track.circuit.p2': 'In this map you can see both the track and strategic points (start, kilometric points...), ' +
        'and other places (showers, restaurants, hostels...)',
    'during.track.circuit.p3': 'You can show and hide the menu by clicking on this icon in the map: ',
    'during.track.start': 'Start',
    'during.track.finish': 'Finish',
    'during.track.km2km': 'Kilometer by Kilometer',
    'during.track.alt': 'Altimetry',
    'during.track.alt.total': 'Total distance',
    'during.track.alt.lowest': 'Lowest point',
    'during.track.alt.uphill': 'Uphill',
    'during.track.alt.flat': 'Flat',
    'during.track.alt.highup': 'Top uphill',
    'during.track.alt.longup': 'Longest uphill',
    'during.track.alt.highdown': 'Top downlhill',
    'during.track.alt.longdown': 'Longest downhill',
    'during.track.alt.totalud': 'Total Uphill / Downhill',
    'during.track.alt.highest': 'Highest point',
    'during.track.alt.lowing': 'Downhill',
    'during.track.alt.gain': 'Gain in height',
    'during.track.alt.meters': 'Ramps in meters',
    'during.track.alt.percentage': 'Ramps in percentage',

    /** Drinking */
    'during.drinking.subtitle': 'All runners will get proper drinking supply. You will have in the middle of the track ' +
        'some kind of liquid support.',
    'during.drinking.heading': 'Hydration matters',
    'during.drinking.subheading': 'Runners will have the opportunity of getting liquid supplies with both water and an ' +
        'isotonic drink in the Paseo dos Leóns at Alameda, just at the middle of the track.',
    'during.drinking.p1': 'Supplies provided thanks to our sponsors:',
    'during.drinking.p2': 'Moreover, once reached the finish line, you will get a complete liquid support pack ' +
        'with food at Praza do Obradoiro, where you might find as well a surprise.',

    /** Clothes */
    'during.clothes.subtitle': 'We will provide a wardrobe service, so you will only have to worry about enjoying the race. ' +
        'It will be free of charge for all the runners.',
    'during.clothes.heading': 'In good faith',
    'during.clothes.subheading1': 'We will set up in the <b>Pazo de Raxoi</b>\'s location, at <b>Praza do ' +
        'Obradoiro</b>, a wardrobe service, just next to the finish line, and very close to the start.',
    'during.clothes.subheading2': 'This service will be available from <b>20:30</b> to <b>24:00</b>.',
    'during.clothes.p1': '<p>We kindly ask you to come with your bag and the sticker with your dorsal number that was provided ' +
        'in the runner bag. This makes easier the collect and return of all bags.</p><p>Here you have an ' +
        'example of how to place this sticker:</p>',
    'during.clothes.p2': 'If you don\'t have any bag, we will place your items in a plastic bag to be stored in the ' +
        'wardrobe. If you have the sticker we will use it, if not we will just write the dorsal number and safely keep ' +
        'it until you come back from the race.',
    'during.clothes.p3': '<b>It is compulsory that, at both deposit and pick up, you come with your dorsal</b>. ' +
        'Else it may be impossible for us to give it back to you.',

    /** Dinner */
    'after.dinner.subtitle': 'After the race you have to get your strength back. Here are our recommendations.',

    /** Sleeping */
    'after.sleeping.subtitle': 'Now that you already are in Santiago, why not spend a few days enjoying it?',
    'after.sleeping.heading': 'Recommended accommodation',
    'after.sleeping.subheading': 'We help you seeking for accommodation to avoid traveling during night, don\'t ' +
        'miss the fantastic weekend we are preparing. Stay and live the Compostela\'s magic nights.',
    'after.sleeping.p': 'These are the hotels that work with us with special prices, there are choices for ' +
        'all budgets',
    'after.sleeping.check': 'Check in the map the location of these places (by opening the menu)',

    /** Showers */
    'during.showers.subtitle': 'Once finishing a race there is no better feeling than getting a shower ' +
        'after all efforts made. SantYaGo10K gives for free some locations to get this shower.',
    'during.showers.heading': 'Where to get a shower',
    'during.showers.subheading': 'Once you get to the finish line, it is very likely that you want to get a fresh shower. ' +
        'So, after you finish the race, you can go to two different strategic places to get that wanted shower.',
    'during.showers.time': 'Timetable',
    'during.showers.address': 'Address',
    'during.showers.isabel': 'S.F. Santa Isabel',
    'during.showers.isabel.title': 'Swimming-pool at Sports Facilities Santa Isabel',
    'during.showers.isabel.p': 'Remember we have an agreement with Parking Catedral, which is very close to these ' +
        'facilities, in case you want to leave your car there. <a>i</a>.',
    'during.showers.lorenzo': 'Pavilion Lorenzo de la Torre',
    'during.showers.lorenzo.title': 'Municipal Pavilion Lorenzo de la Torre',
    'during.showers.lorenzo.p': 'These showers are pretty close to the Praza do Obradoiro, in the finish line. You will be ' +
        'very near to the <a>i</a>.',

    /** Prizes */
    'during.prizes.subtitle': 'All efforts get their reward. We give lots of prizes to a large number of ' +
        'participants; you may get one!',
    'during.prizes.heading': 'We try to award the maximum',
    'during.prizes.subheading': 'In the race day, after publishing the results in the news board, ' +
        'the prize award ceremoy will take place in the podium located at Praza do Obradoiro.',
    'during.prizes.p': 'Athletes shall be next to the podium zone and be aware for their call through public address ' +
        'just when the prize award takes place. It is required to show either DNI or passport as identification.',
    'during.prizes.masc': 'Male category',
    'during.prizes.fem': 'Female category',
    'during.prizes.pos1': '1<sup>st</sup> absolute position',
    'during.prizes.pos2': '2<sup>nd</sup> absolute position',
    'during.prizes.pos3': '3<sup>rd</sup> absolute position',
    'during.prizes.stgo.pos1': '1<sup>st</sup> resident',
    'during.prizes.stgo.pos2': '2<sup>rd</sup> resident',
    'during.prizes.stgo.pos3': '3<sup>rd</sup> resident',
    'during.prizes.others': 'Other categories',
    'during.prizes.others.desc': 'The first three finalist of all male and female sub-categories will also get a ' +
        'trophy and a prize',
    'during.prizes.special': 'Special categories',
    'during.prizes.special.1': 'Federated Club with the most finalist',
    'during.prizes.special.2': 'Non-Federated Group with the most finalist',
    'during.prizes.special.3': 'Non-Federated Group with the most finalist',
    'during.prizes.special.4': 'Club or Group that comes from the most farthest location',
    'during.prizes.special.5': 'Special award “Cándido Calvo”, for the oldest participant',
    'during.prizes.terms': 'Rules',
    'during.prizes.terms.desc': 'To be eligible for prizes, you must know that:',
    'during.prizes.terms.1': 'Prizes are not cumulative.',
    'during.prizes.terms.2': 'To be apt for a Federated Club or Non-Federated Group, athletes must make the inscription ' +
        'individually and, later on, send an email to the inbox <a>i</a> with a list of all members to be enrolled.',
    'during.prizes.terms.3': 'In case of draw for clubs/groups, the one with the lowest sum of times needed for all ' +
        'finished participants will be awarded. The same applies to oldest runners.',

    /** Visiting */
    'after.visiting.subtitle': 'Santiago is a World Heritage City. You will find thousands of yourist places ' +
        'to visit during your stay.',
    'after.visiting.heading': 'Turism in Santiago',
    'after.visiting.p1': 'Our friends at the Tourist Office of Santiago de Compostela give a few ' +
        'tips to enjoy your stay in the city. Moreover, you can find some proposals customized for the amount of ' +
        'days you will be in Santiago.',
    'after.visiting.p2': 'And to make it even more interesting, here are two videos of some stuff you can do here.',

    /** Voluntarios */
    'volunteer.subtitle': 'Become part of the SantYaGo10K\'s family',
    'volunteer.heading': 'This is more than a race',
    'volunteer.p1': 'Organizing the SantYaGo10K\' race is a long-distance race (sorry for the pun). A great time ' +
        'is working on it since months ago, so that everything runs smoothly on the race day.',
    'volunteer.p2': 'But we\'re not enough. We would like to ask for your help, as volunteers are needed for such a huge ' +
        'kind of event. The more we are, the better!',
    'volunteer.a1': 'Join us the on 2<sup>nd</sup> May, and cooperate with us in the race!',
    'volunteer.closed': 'Applications to volunteer have not yet been opened',
    'volunteer.p3': 'Just input your data in the following form. You will get a snack and prizes.',
    'volunteer.a2': '<b>Under-aged</b> must send us a document previously signed by their parents/tutors ' +
        'which you can download <a>here</a>.',
    'volunteer.a3': '<b>University alumni</b> can apply for credits in the USC through the University Participation ' +
        'and Integration Service.',

    /** Past Years */
    'info.pastyears.heading': 'Past years',
    'info.pastyears.subheading': 'Discover the beginnings of this race',
    'info.pastyears.more': 'Read more',
    'info.pastyears.year': 'Year',
    'info.pastyears.ed.1': '1<sup>st</sup> Edition',
    'info.pastyears.ed.2': '2<sup>nd</sup> Edition',
    'info.pastyears.ed.3': '3<sup>rd</sup> Edition',
    'info.pastyears.ed.4': '4<sup>th</sup> Edition',
    'info.pastyears.ed.5': '5<sup>th</sup> Edition',
    'info.pastyears.origin': 'The beginnings',
    'info.pastyears.origins': '<p>' +
        'This race was created as a joint between Athletics Club Fontes do Sar and Multiusos Fontes do Sar – ' +
        'Sports Facility Santa Isabel, with the intention of brining to the city of Santiago de Compostela a Night' +
        'Affiliated Race.</p>' +
        '<p>We have worked to create a track which allows enjoying the old town of the city to both local and foreign ' +
        'runners, and at the same time offer a ' +
        'flat track as much as possible, and which will delight to anyone who makes athletism their life.</p>' +
        '<p>We have the support of the Townhall of Santiago through several departments, among them ' +
        'the Councilor of Social Services with the Municipal Department of Sports in the Townhall ' +
        'and the Councilor of Economics, talking about Turism in Santiago</p>' +
        '<p>Our intentention is create and stablish an annual event; we aim to became a sports and touristic event' +
        'referent, as other high-class competitions are in other cities in Galicia ' +
        '(Coruña, Vigo, Ourense, etc). But, at the top of everything… ENJOY RUNNING!</p>',

    /** Regulation */
    'info.regulation.subtitle': 'Night Race SantYaGo10K\'s Regulation',
    'info.regulation.galician': 'Galician',

    /** Terms */
    'info.terms.subtitle': 'Terms and Conditions for this website usage',

    /** Press */
    'info.press.subtitle': 'We\'ll be linking here all press coverage related to the race.',
    'info.press.read': 'Read news',

    /** Contact */
    'contact.heading': 'Questions?',
    'contact.subheading': 'Send us an email and we\'ll get back as soon as possible.',
    'contact.subject': 'Subject',
    'contact.message': 'Message...',
    'contact.send': 'Send',
};
