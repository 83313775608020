import React, {createRef} from "react";

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input
} from "reactstrap";
import {useIntl} from "react-intl";
import {Helmet} from "react-helmet";
import {HorizontalSponsors} from '../modules/layout';
import {CONTACT} from "../const";

export const Contact: React.FC = () => {
    const {formatMessage} = useIntl();
    const subj = createRef<HTMLInputElement>();
    const body = createRef<HTMLInputElement>();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, []);

    function sendMessage() {
        const s_subj = subj.current !== null ? "subject=" + encodeURI(subj.current.value) : "";
        const s_body = body.current !== null ? "body=" + encodeURI(body.current.value) : "";
        window.open("mailto:" + CONTACT + "?" + s_subj + "&" + s_body, "_blank");
    }

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "contact.title"})} | SantYaGo10K 2020</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-shaped section-lg">
                        <div className="shape shape-style-1 bg-gradient-default">
                            <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="pt-lg-md">
                            <Row className="justify-content-center">
                                <Col lg="8">
                                    <Card className="bg-gradient-secondary shadow">
                                        <CardBody className="p-lg-5">
                                            <h4 className="mb-1">{formatMessage({id: 'contact.heading'})}</h4>
                                            <p className="mt-0">
                                                {formatMessage({id: 'contact.subheading'})}
                                            </p>
                                            <FormGroup>
                                                <InputGroup className="input-group-alternative">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-email-83"/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder={formatMessage({id: 'contact.subject'})}
                                                        type="text"
                                                        required={true}
                                                        innerRef={subj}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup className="mb-4">
                                                <Input
                                                    className="form-control-alternative"
                                                    cols="80"
                                                    name="name"
                                                    placeholder={formatMessage({id: 'contact.message'})}
                                                    rows="4"
                                                    type="textarea"
                                                    required={true}
                                                    innerRef={body}
                                                />
                                            </FormGroup>
                                            <div>
                                                <Button
                                                    block
                                                    className="btn-round"
                                                    color="default"
                                                    size="lg"
                                                    type="button"
                                                    onClick={sendMessage}
                                                >
                                                    {formatMessage({id: 'contact.send'})}
                                                </Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    {/* 1st Hero Variation */}
                    <HorizontalSponsors/>
                </div>
            </main>
        </>
    );
};

export default Contact;
