import React from "react";
import {Helmet} from 'react-helmet';

import {
    Container,
    Row,
    Col, Badge, Card, CardBody,
} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {VerticalSponsors} from "../../modules/layout";


export const Sleeping: React.FC = () => {
    const {formatMessage} = useIntl();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "after.sleeping.title"})} | SantYaGo10K 2020</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped">
                        <div className="shape shape-style-1 bg-gradient-green">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "after.sleeping.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "after.sleeping.subtitle"})}
                                        </p>
                                    </Col>
                                    <Col lg="6">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require('../../assets/img/race/pages/sleeping.jpg')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section section-sm">
                        <Container>
                            <Row>
                                <Col lg="8">
                                    <Badge color="warning" pill className="mr-1">
                                        <FormattedMessage id="wip.confirmation"/>
                                    </Badge>
                                    <h1><FormattedMessage id="after.sleeping.heading"/></h1>
                                    <Row>
                                        <Col lg="6">
                                            <p><FormattedMessage id="after.sleeping.subheading"/></p>
                                        </Col>
                                        <Col lg="6">
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require('../../assets/img/undraw/sleeping.png')}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p><FormattedMessage id="after.sleeping.p"/></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <Card className="shadow shadow-sm mt-5">
                                                <CardBody>
                                                    <div className="d-flex px-3">
                                                        <div className=" pl-4">
                                                            <h5 className="title text-warning">
                                                                <i className="fa fa-star"/><i className="fa fa-star"/><i className="fa fa-star"/> Hotel "San Lourenzo"
                                                            </h5>
                                                            <p>Rua San Lourenzo, 2-8<br/>15705 - Santiago de Compostela</p>
                                                            <p><span className="fa fa-phone"/> (+34) 981 58 01 33</p>
                                                            <p><span className="fa fa-globe"/> <a
                                                                href="http://www.hsanlorenzo.com/" target="_blank"
                                                                rel="noopener noreferrer">hsanlorenzo.com</a></p>
                                                            <p><span className="fa fa-envelope"/> <a
                                                                href="mailto:info@hsanlorenzo.com" target="_blank"
                                                                rel="noopener noreferrer">info@hsanlorenzo.com</a></p>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="6">
                                            <Card className="shadow shadow-sm mt-5">
                                                <CardBody>
                                                    <div className="d-flex px-3">
                                                        <div className="pl-4">
                                                            <h5 className="title text-warning">
                                                                <i className="fa fa-star"/><i className="fa fa-star"/> Hostal "Campo das Estrelas"
                                                            </h5>
                                                            <p>Rúa do Pombal, 41<br/>15707 - Santiago de Compostela</p>
                                                            <p><span className="fa fa-phone"/> (+34) 881 25 24 77</p>
                                                            <p><span className="fa fa-globe"/> <a
                                                                href="http://www.campodeestrelas.com/" target="_blank"
                                                                rel="noopener noreferrer">campodeestrelas.com</a></p>
                                                            <p><span className="fa fa-envelope"/> <a
                                                                href="mailto:hostal@campodeestrelas.com" target="_blank"
                                                                rel="noopener noreferrer">hostal@campodeestrelas.com</a></p>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="6">
                                            <Card className="shadow shadow-sm mt-5">
                                                <CardBody>
                                                    <div className="d-flex px-3">
                                                        <div className="pl-4">
                                                            <h5 className="title text-warning">
                                                                <i className="fa fa-star"/> Parador "Fonseca"
                                                            </h5>
                                                            <p>Rúa de Fonseca Nº1 – 2ºP<br/>15707 - Santiago de Compostela</p>
                                                            <p><span className="fa fa-phone"/> (+34) 981 89 03 78</p>
                                                            <p><span className="fa fa-globe"/> <a
                                                                href="http://www.pensionfonseca.com/" target="_blank"
                                                                rel="noopener noreferrer">pensionfonseca.com</a></p>
                                                            <p><span className="fa fa-envelope"/> <a
                                                                href="mailto:reservas@pensionfonseca.com" target="_blank"
                                                                rel="noopener noreferrer">reservas@pensionfonseca.com</a></p>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <Col>
                                            <h3><FormattedMessage id="after.sleeping.check"/></h3>
                                            <Card className="shadow border-0">
                                                <CardBody className="py-0 px-0 pr-0 pl-0 pt-0 pb-0" style={{height: 512}}>
                                                    <iframe
                                                        title="map"
                                                        src="https://www.google.com/maps/d/u/0/embed?mid=1WmQ7-W6d06_uswEl11U7Z5vLMkNK66Tm&z=14"
                                                        width="100%"
                                                        height="512"
                                                        style={{border: "none"}}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="4">
                                    <VerticalSponsors/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Sleeping;
