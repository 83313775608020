import React from "react";
import {Helmet} from 'react-helmet';

import {
    Container,
    Row,
    Col,
    Badge,
} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {VerticalSponsors} from "../../modules/layout";


export const Drinking: React.FC = () => {
    const {formatMessage} = useIntl();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "during.drinking.title"})} | SantYaGo10K 2020</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped">
                        <div className="shape shape-style-1 bg-gradient-orange">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "during.drinking.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "during.drinking.subtitle"})}
                                        </p>
                                    </Col>
                                    <Col lg="6">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require('../../assets/img/race/pages/drinking.jpg')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section section-sm">
                        <Container>
                            <Row>
                                <Col lg="8">
                                    <Badge color="warning" pill className="mr-1">
                                        <FormattedMessage id="wip.confirmation"/>
                                    </Badge>
                                    <h1><FormattedMessage id="during.drinking.heading"/></h1>
                                    <Row>
                                        <Col lg="6">
                                            <p><FormattedMessage id="during.drinking.subheading"/></p>
                                            <p><FormattedMessage id="during.drinking.p1"/></p>
                                            <Row>
                                                <Col lg="10">
                                                    <img
                                                        alt="..."
                                                        className="img-fluid mb-4"
                                                        src={require("../../assets/img/race/sponsors/other/feiraco.png")}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg="6">
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require('../../assets/img/undraw/drinking.png')}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p><FormattedMessage id="during.drinking.p2"/></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <img
                                                alt="..."
                                                className="img-fluid floating mt-4 shadow-lg"
                                                src={require("../../assets/img/race/others/drinking.jpg")}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="4">
                                    <VerticalSponsors/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Drinking;
