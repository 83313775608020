import React from 'react';
import {IntlProvider} from 'react-intl';
import {Route, Redirect} from 'react-router-dom';
import {AppLanguage, AppLanguages, DefaultLanguage} from '../../../const';

import {LanguageStrings} from '../localizations';
import ReactGA from "react-ga";
import Cookies from "universal-cookie";
import axios from 'axios'

interface Props {
    RouterComponent: React.ComponentClass<any>;
    languages: { [k: number]: string };
    appStrings: { [prop: string]: LanguageStrings };
    defaultLanguage?: AppLanguage;
}

export const LocalizedRouter: React.FC<Props> = ({
                                                     children,
                                                     RouterComponent,
                                                     appStrings,
                                                     defaultLanguage
                                                 }) => (
    <RouterComponent>
        <Route path="/:locale([a-z]{2})">
            {({match, location}) => {
                const cookies = new Cookies();
                const params = match ? match.params : {};
                const {locale = defaultLanguage || AppLanguage.Galician} = params;

                // Current language
                const lang = AppLanguages.includes(locale) ? locale : AppLanguage.Galician;
                // Current path, including language
                const {pathname} = location;
                // Current path, without language code
                const path = DefaultLanguage === lang ? pathname : pathname.replace(`/${lang}`, ``);

                if (cookies.get("l10n") === undefined)
                    cookies.set('l10n', lang, {path: '/'});
                // Current language in cookies
                const clang = cookies.get("l10n");
                if  (cookies.get("i18n") === undefined)
                    cookies.set('i18n', "0", {path: '/'});
                const credir = cookies.get("i18n");

                if (credir === "0") {
                    axios.get('https://ipapi.co/json/').then((response) => {
                        let region = response.data.region_code;
                        let country = response.data.country_code;
                        cookies.set('i18n', "1", {path: '/'});
                        if (region === "GA" && country === "ES")
                            return <Redirect to={`/${path}`}/>;
                        else if (country === "ES")
                            return <Redirect to={`/es${path}`}/>;
                        else
                            return <Redirect to={`/en${path}`}/>;
                    }).catch((error) => {
                        console.log(error);
                    });
                }

                /**
                 * Cookie language does not match path language, only in global root
                 * Lang = old
                 * Clang = new
                 */
                if ((path === "/" || path === "") && lang === DefaultLanguage && lang !== clang)
                    return <Redirect to={`/${clang}${path}`}/>;
                else if (lang !== DefaultLanguage && lang !== clang)
                    cookies.set('l10n', lang, {path: '/'});

                /**
                 * If default language and in path, remove it
                 */
                if (locale === DefaultLanguage && pathname.startsWith(`/${lang}`))
                    return (<Redirect to={path}/>);

                /**
                 * If language is not in route path, redirect to language root.
                 * If not, return Intl provider with default language set
                 */
                ReactGA.pageview(pathname);
                if (!pathname.startsWith(`/${lang}`))
                    return (
                        <IntlProvider locale={DefaultLanguage} messages={appStrings[lang]}>
                            {children}
                        </IntlProvider>
                    );
                return (
                    <IntlProvider locale={lang} messages={appStrings[lang]}>
                        {children}
                    </IntlProvider>
                );
            }}
        </Route>
    </RouterComponent>
);