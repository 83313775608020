import React from "react";
import {Helmet} from 'react-helmet';

import {
    Container,
    Row,
    Col,
} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {VerticalSponsors} from "../../modules/layout";
import YouTube, {Options} from "react-youtube";
import {Link} from "react-router-dom";
import {AppRoute, AppRouteTitles} from "../../const";


export const Chip: React.FC = () => {
    const {formatMessage} = useIntl();
    const dorsal = AppRoute.Dorsal;
    const yt: Options = {
        width: '100%',
        playerVars: {
            autoplay: 0
        }
    };

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "before.chip.title"})} | SantYaGo10K 2020</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped">
                        <div className="shape shape-style-1 bg-gradient-blue">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "before.chip.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "before.chip.subtitle"})}
                                        </p>
                                    </Col>
                                    <Col lg="6">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require('../../assets/img/race/pages/chip.jpg')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section section-sm">
                        <Container>
                            <Row>
                                <Col lg="8">
                                    <h1>{formatMessage({id: "before.chip.heading"})}</h1>
                                    <Row>
                                        <Col lg="6">
                                            <p>
                                                <FormattedMessage
                                                    id="before.chip.subheading"
                                                    values={{
                                                        a: () => (
                                                            <Link to={dorsal}>
                                                                {formatMessage({id: AppRouteTitles.get(AppRoute.Dorsal)})}
                                                            </Link>
                                                        ),
                                                        b: (chunks: React.ReactNode) => (
                                                            <b style={{fontWeight: "bold"}}>
                                                                {chunks}
                                                            </b>
                                                        ),
                                                    }}
                                                />
                                            </p>
                                        </Col>
                                        <Col lg="6">
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require("../../assets/img/undraw/chip.png")}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p>
                                                <FormattedMessage
                                                    id="before.chip.p1"
                                                    values={{
                                                        b: (chunks: React.ReactNode) => (
                                                            <b style={{fontWeight: "bold"}}>
                                                                {chunks}
                                                            </b>
                                                        ),
                                                    }}
                                                />
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <Col>
                                            <img
                                                alt="..."
                                                className="img-fluid shadow"
                                                src={require("../../assets/img/race/others/chip.png")}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-5 mb-4">
                                        <Col>
                                            <p>
                                                <FormattedMessage
                                                    id="before.chip.p2"
                                                    values={{
                                                        b: (chunks: React.ReactNode) => (
                                                            <b style={{fontWeight: "bold"}}>
                                                                {chunks}
                                                            </b>
                                                        ),
                                                    }}
                                                />
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <YouTube
                                                className="shadow"
                                                videoId="2MJ6ib7QH2s"
                                                opts={yt}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="4">
                                    <VerticalSponsors/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Chip;
